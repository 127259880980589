import { SVGProps } from 'react';
const SvgOctagonCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M8.683 3h6.634a1 1 0 0 1 .707.293l4.683 4.683a1 1 0 0 1 .293.707v6.634a1 1 0 0 1-.293.707l-4.683 4.683a1 1 0 0 1-.707.293H8.683a1 1 0 0 1-.707-.293l-4.683-4.683A1 1 0 0 1 3 15.317V8.683a1 1 0 0 1 .293-.707l4.683-4.683A1 1 0 0 1 8.683 3Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m15 10-4 4-2-2"
    />
  </svg>
);
export default SvgOctagonCheck;
