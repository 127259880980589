import { SVGProps } from 'react';
const SvgTag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m4.034 12.52 7.447 7.446a1 1 0 0 0 1.414 0l7.071-7.07a1 1 0 0 0 0-1.415L12.52 4.034a1 1 0 0 0-.797-.289l-6.482.59a1 1 0 0 0-.905.905l-.59 6.482a1 1 0 0 0 .29.797Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.299 8.299a1 1 0 1 1 1.414 1.414 1 1 0 0 1-1.414-1.414Z"
    />
  </svg>
);
export default SvgTag;
