import { SVGProps } from 'react';
const SvgListUnordered = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 17h10M5 17h.002v.002H5V17ZM9 12h10M5 12h.002v.002H5V12ZM9 7h10M5 7h.002v.002H5V7Z"
    />
  </svg>
);
export default SvgListUnordered;
