import { SVGProps } from 'react';
const SvgCookie = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.985 21c-4.96-.006-8.98-4.032-8.985-9 .354.1.72.151 1.087.152a3.691 3.691 0 0 0 3.055-1.581c.623-.9.754-2.053.35-3.071.259.046.52.07.782.07a3.832 3.832 0 0 0 3.006-1.42c.719-.88 1-2.038.764-3.15 4.962.016 8.972 4.059 8.956 9.03-.016 4.97-4.052 8.986-9.015 8.97Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10 17h.002v.002H10V17ZM15 15h.002v.002H15V15ZM11 12h.002v.002H11V12ZM16 10h.002v.002H16V10ZM3 7h.002v.002H3V7ZM8 3h.002v.002H8V3ZM4 3h.002v.002H4V3Z"
    />
  </svg>
);
export default SvgCookie;
