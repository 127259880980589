import { SVGProps } from 'react';
const SvgFileBlank = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 21H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.563a1 1 0 0 1 .734.32l4.437 4.792a1 1 0 0 1 .266.68V20a1 1 0 0 1-1 1Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 9h-5a1 1 0 0 1-1-1V3"
    />
  </svg>
);
export default SvgFileBlank;
