import { SVGProps } from 'react';
const SvgTwitch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.002 0 3.43 8.571V39.43h10.286V48l8.571-8.571h6.857L44.573 24V0H12Zm29.142 22.286-6.857 6.857H27.43l-6 6v-6h-7.714V3.429h27.428v18.857Z"
    />
    <path
      fill="currentColor"
      d="M36.001 9.429h-3.428v10.285H36V9.43ZM26.573 9.429h-3.429v10.285h3.429V9.43Z"
    />
  </svg>
);
export default SvgTwitch;
