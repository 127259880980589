import { SVGProps } from 'react';
const SvgNavigation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20.767 10.27c.808-.403.71-1.585-.153-1.85L4.805 3.555a1 1 0 0 0-1.25 1.25l4.865 15.81c.265.862 1.447.96 1.85.152l3.35-6.7a1 1 0 0 1 .447-.447l6.7-3.35Z"
    />
  </svg>
);
export default SvgNavigation;
