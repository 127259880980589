import { SVGProps } from 'react';
const SvgLinkBreak = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 20v-2M18 16h2M7.05 11.293l-1.414 1.414a4 4 0 1 0 5.657 5.657l1.414-1.414M6 8H4M11.293 7.05l1.414-1.414a4 4 0 1 1 5.657 5.657l-1.414 1.414M8 4v2"
    />
  </svg>
);
export default SvgLinkBreak;
