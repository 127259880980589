import { SVGProps } from 'react';
const SvgLinkHorizontal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 12h8M15 8h2a4 4 0 0 1 0 8h-2M9 8H7a4 4 0 1 0 0 8h2"
    />
  </svg>
);
export default SvgLinkHorizontal;
