import { SVGProps } from 'react';
const SvgChartBarHorizontal01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13 9V4a1 1 0 0 0-1-1H4v6h9ZM20 14v-4a1 1 0 0 0-1-1H4v6h15a1 1 0 0 0 1-1ZM16 20v-5H4v6h11a1 1 0 0 0 1-1Z"
    />
  </svg>
);
export default SvgChartBarHorizontal01;
