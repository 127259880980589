import { SVGProps } from 'react';
const SvgLeaf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6.829 17.08C2.586 10.01 8.243 4.354 18.849 5.06c.708 10.606-4.949 16.263-12.02 12.02ZM5 18.91l5.657-5.658"
    />
  </svg>
);
export default SvgLeaf;
