import { SVGProps } from 'react';
const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m19.167 7.94-.066-.114a2 2 0 0 1-.268-1.034l.03-1.779a1 1 0 0 0-.5-.883l-2.737-1.577a1 1 0 0 0-1.013.009l-1.519.91a2 2 0 0 1-1.027.283h-.132a2 2 0 0 1-1.03-.285L9.38 2.554a1 1 0 0 0-1.016-.008L5.635 4.13a1 1 0 0 0-.498.881l.03 1.781a2 2 0 0 1-.268 1.034l-.065.113a2 2 0 0 1-.762.748l-1.555.862a1 1 0 0 0-.516.877l.008 3.152a1 1 0 0 0 .513.872l1.548.86a2 2 0 0 1 .763.755l.07.121a2 2 0 0 1 .264 1.027l-.03 1.773a1 1 0 0 0 .5.884l2.737 1.577a1 1 0 0 0 1.013-.009l1.52-.91a2 2 0 0 1 1.027-.283h.131a2 2 0 0 1 1.03.285l1.526.916a1 1 0 0 0 1.016.008l2.729-1.584a1 1 0 0 0 .497-.881l-.03-1.781a2 2 0 0 1 .268-1.034l.065-.113a2 2 0 0 1 .763-.749l1.555-.861a1 1 0 0 0 .515-.877l-.007-3.152a1 1 0 0 0-.514-.872l-1.551-.863a2 2 0 0 1-.76-.747Z"
    />
  </svg>
);
export default SvgSettings;
