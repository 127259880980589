import { SVGProps } from 'react';
const SvgTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M15.1 43.5c18.11 0 28.017-15.006 28.017-28.016 0-.422-.01-.853-.029-1.275A19.998 19.998 0 0 0 48 9.11c-1.795.798-3.7 1.32-5.652 1.546a9.9 9.9 0 0 0 4.33-5.445 19.794 19.794 0 0 1-6.251 2.39 9.86 9.86 0 0 0-16.788 8.979A27.97 27.97 0 0 1 3.346 6.299 9.859 9.859 0 0 0 6.393 19.44a9.86 9.86 0 0 1-4.462-1.228v.122a9.844 9.844 0 0 0 7.901 9.656 9.788 9.788 0 0 1-4.442.169 9.867 9.867 0 0 0 9.195 6.843A19.75 19.75 0 0 1 0 39.078 27.937 27.937 0 0 0 15.1 43.5Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTwitter;
