import { SVGProps } from 'react';
const SvgPaperPlane = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m10.308 13.692 4.846-4.846M3.233 10.27c-.807-.403-.71-1.585.153-1.85l15.809-4.865a1 1 0 0 1 1.25 1.25l-4.865 15.81c-.265.862-1.446.96-1.85.152l-3.35-6.7a1 1 0 0 0-.447-.447l-6.7-3.35Z"
    />
  </svg>
);
export default SvgPaperPlane;
