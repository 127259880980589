import { SVGProps } from 'react';
const SvgChatConversation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 8h4a1 1 0 0 1 1 1v11l-3.333-2.77a1 1 0 0 0-.64-.23H9a1 1 0 0 1-1-1v-3"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 4H4a1 1 0 0 0-1 1v11l3.333-2.77a1 1 0 0 1 .64-.23H15a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1Z"
    />
  </svg>
);
export default SvgChatConversation;
