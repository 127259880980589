import { SVGProps } from 'react';
const SvgShoppingCart02 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 20a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM7 20a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM3 3h.768a1 1 0 0 1 .983.818L7 16M5.5 6h14.243a1 1 0 0 1 .974 1.225l-1.846 8a1 1 0 0 1-.974.775H7"
    />
  </svg>
);
export default SvgShoppingCart02;
