import { SVGProps } from 'react';
const SvgBuilding01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2 20h20M19.668 12.643l-4.5-4.043a1 1 0 0 0-1.336 0l-4.5 4.043a1 1 0 0 0-.332.744V20h10a1 1 0 0 0 1-1v-5.613a1 1 0 0 0-.332-.744Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 20H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v5"
    />
  </svg>
);
export default SvgBuilding01;
