import { SVGProps } from 'react';
const SvgDribbble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24 0C10.75 0 0 10.75 0 24s10.75 24 24 24c13.223 0 24-10.75 24-24S37.223 0 24 0Zm15.852 11.063a20.406 20.406 0 0 1 4.634 12.755c-.677-.13-7.445-1.51-14.265-.651-.156-.338-.286-.703-.442-1.067a61.043 61.043 0 0 0-1.354-2.968c7.549-3.071 10.985-7.496 11.427-8.07ZM24 3.54c5.206 0 9.97 1.952 13.588 5.154-.365.52-3.462 4.66-10.75 7.393-3.359-6.17-7.08-11.22-7.654-12A20.847 20.847 0 0 1 24 3.54Zm-8.72 1.926c.546.73 4.19 5.805 7.6 11.844-9.578 2.551-18.038 2.5-18.95 2.5C5.259 13.457 9.554 8.173 15.28 5.465ZM3.488 24.026v-.625c.885.026 10.829.157 21.059-2.915a60.958 60.958 0 0 1 1.666 3.488c-.26.078-.547.156-.807.234-10.569 3.41-16.191 12.729-16.66 13.51a20.451 20.451 0 0 1-5.258-13.692ZM24 44.512c-4.738 0-9.11-1.614-12.573-4.321.365-.755 4.53-8.772 16.087-12.807.052-.026.078-.026.13-.052 2.89 7.47 4.061 13.744 4.373 15.54A20.162 20.162 0 0 1 24 44.512Zm11.427-3.514c-.208-1.25-1.301-7.237-3.982-14.603 6.43-1.015 12.052.65 12.755.885-.885 5.7-4.165 10.62-8.773 13.718Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDribbble;
