import { SVGProps } from 'react';
const SvgShoppingBag02 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.82 8H4.18a1 1 0 0 0-.986 1.164l1.667 10a1 1 0 0 0 .986.836h12.306a1 1 0 0 0 .986-.836l1.667-10A1 1 0 0 0 19.82 8ZM8 8a4 4 0 1 1 8 0"
    />
  </svg>
);
export default SvgShoppingBag02;
