import { SVGProps } from 'react';
const SvgShieldWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 3H5a1 1 0 0 0-1 1v6.165c0 7.381 6.274 10.187 7.71 10.732.19.072.39.072.58 0 1.436-.545 7.71-3.35 7.71-10.732V4a1 1 0 0 0-1-1Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.95 15h.1v.1h-.1V15ZM12 8v4"
    />
  </svg>
);
export default SvgShieldWarning;
