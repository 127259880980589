import { SVGProps } from 'react';
const SvgBook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 20.25V5a2 2 0 0 1 2-2h11a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1M5 20.25A2.25 2.25 0 0 1 7.25 18H18M5 20.25c0 .414.336.75.75.75H17a1 1 0 0 0 1-1v-2"
    />
  </svg>
);
export default SvgBook;
