import { SVGProps } from 'react';
const SvgChatConversationCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 10a6 6 0 0 1-9.21 5.07L3 16l.93-2.79A6 6 0 1 1 15 10Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9.338 15.99a6.002 6.002 0 0 0 8.872 3.08L21 20l-.93-2.79.142-.236A6 6 0 0 0 15 8l-.225.004-.113.006"
    />
  </svg>
);
export default SvgChatConversationCircle;
