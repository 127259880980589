import { SVGProps } from 'react';
const SvgSingleQuotesL = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 16a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1v-4h4a1 1 0 0 1 1 1v3ZM13 7a3 3 0 0 0-3 3v2"
    />
  </svg>
);
export default SvgSingleQuotesL;
