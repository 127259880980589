import { SVGProps } from 'react';
const SvgCloudOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 19H6a5 5 0 0 1-.332-9.99A7.016 7.016 0 0 1 7 7.102m3-1.811A7.002 7.002 0 0 1 18.93 11H19a4 4 0 0 1 3.123 6.5M5 5l16 16"
    />
  </svg>
);
export default SvgCloudOff;
