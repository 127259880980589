import {
  Avatar,
  Button,
  ButtonVariant,
  Icons,
  Input,
  Popover,
  theme,
} from '@nesso/ui';
import { useSignOut } from 'react-firebase-hooks/auth';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { firebaseAuth } from '../../firebase';
import { userState } from '../user/user.state';
import { NotificationList } from './NotificationList/NotificationList';
import * as css from './Profile.css';

export function NavProfile() {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const [signOutFirebase] = useSignOut(firebaseAuth);
  const signOut = async () => {
    await signOutFirebase();
    navigate('/login');
  };

  return (
    <div className={css.actions}>
      <Input type="search" className={css.search} placeholder="Search..." />
      <Link to="/settings">
        <Icons.Settings width={24} color={theme.color.neutral[900]} />
      </Link>
      <Popover
        trigger={
          <Button variant={ButtonVariant.Ghost}>
            <Icons.BellNotification
              width={24}
              color={theme.color.neutral[900]}
            />
          </Button>
        }
      >
        <NotificationList />
      </Popover>
      <Avatar size={32} imageUrl={user.photoUrl} onClick={signOut} />
    </div>
  );
}
