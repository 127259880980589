import { assignInlineVars } from '@vanilla-extract/dynamic';
import { forwardRef, ReactNode } from 'react';
import { theme } from '../../theme.css';
import * as css from './Column.css';

export interface ColumnProps extends React.AllHTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  gap?: string;
  grow?: boolean;
  justifyContent?:
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'stretch'
    | 'left'
    | 'right'
    | 'space-evenly'
    | 'space-around'
    | 'space-between';
  alignItems?:
    | 'start'
    | 'end'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'stretch';
}

export const Column = forwardRef<HTMLDivElement, ColumnProps>(
  ({ gap, grow, alignItems, justifyContent, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={`${css.column} ${props.className ?? ''}`}
        style={{
          ...assignInlineVars({
            [css.gap]: gap ?? theme.space.none,
            [css.grow]: grow ? '0 1 auto' : 'unset',
            [css.height]: grow ? '100%' : 'auto',
            [css.alignItems]: alignItems ?? 'start',
            [css.justifyContent]: justifyContent ?? 'start',
          }),
          ...props.style,
        }}
      >
        {props.children}
      </div>
    );
  }
);

Column.displayName = 'Column';
