import { pluralize } from './string';

export function humanReadableDuration({
  from,
  to = new Date(),
  format = 'long',
}: {
  from: Date;
  to?: Date;
  format?: 'short' | 'long';
}) {
  const diff = to.getTime() - from.getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  const build = (label: string, value: number, shortUnit: string): string => {
    if (format === 'long') {
      return `${value} ${pluralize(
        label.substring(0, label.length - 1),
        value,
        label
      )}`;
    }

    return `${value}${shortUnit}`;
  };

  if (years > 0) {
    return build('years', years, 'y');
  }
  if (months > 0) {
    return build('months', months, 'mo');
  }

  if (weeks > 0) {
    return build('weeks', weeks, 'w');
  }
  if (days > 0) {
    return build('days', days, 'd');
  }
  if (hours > 0) {
    return build('hours', hours, 'h');
  }
  if (minutes > 0) {
    return build('minutes', minutes, 'm');
  }

  return `just now`;
}
