import { SVGProps } from 'react';
const SvgFolder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 20h16a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H3v13a1 1 0 0 0 1 1ZM12 6l-1.707-1.707A1 1 0 0 0 9.586 4H4a1 1 0 0 0-1 1v1"
    />
  </svg>
);
export default SvgFolder;
