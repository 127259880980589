import { SVGProps } from 'react';
const SvgBell = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 17H9v1a3 3 0 1 0 6 0v-1ZM5 17h14a1 1 0 0 0 1-1v-.586a1 1 0 0 0-.293-.707l-.51-.51a.67.67 0 0 1-.197-.475V10a7 7 0 1 0-14 0v3.722a.67.67 0 0 1-.196.474l-.511.511a1 1 0 0 0-.293.707V16a1 1 0 0 0 1 1Z"
    />
  </svg>
);
export default SvgBell;
