import { SVGProps } from 'react';
const SvgBookOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 5h5a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-4.394a3 3 0 0 0-2.497 1.336L12 20V8a3 3 0 0 1 3-3ZM9 5H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h4.394a3 3 0 0 1 2.497 1.336L12 20V8a3 3 0 0 0-3-3Z"
    />
  </svg>
);
export default SvgBookOpen;
