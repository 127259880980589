import { FC, ReactNode } from 'react';

export interface IfProps {
  when: boolean;
  children: ReactNode;
}

export const If: FC<IfProps> = ({ when, children }) => {
  return when ? <>{children}</> : <></>;
};

If.displayName = 'If';
