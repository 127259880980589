import { SVGProps } from 'react';
const SvgFolderEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 8V7a1 1 0 0 0-1-1H3v13a1 1 0 0 0 1 1h3M11 21h2.5l7.5-7.5-2.5-2.5-7.5 7.5V21ZM16 14l2 2M12 6l-1.707-1.707A1 1 0 0 0 9.586 4H4a1 1 0 0 0-1 1v1"
    />
  </svg>
);
export default SvgFolderEdit;
