import { SVGProps } from 'react';
const SvgLabel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15.532 6H5a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10.532a1 1 0 0 0 .768-.36l4.166-5a1 1 0 0 0 0-1.28l-4.166-5a1 1 0 0 0-.768-.36Z"
    />
  </svg>
);
export default SvgLabel;
