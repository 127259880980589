import { SVGProps } from 'react';
const SvgInstance = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M21.9 11.293 12.707 2.1a1 1 0 0 0-1.414 0L2.1 11.293a1 1 0 0 0 0 1.414l9.192 9.192a1 1 0 0 0 1.414 0l9.192-9.192a1 1 0 0 0 0-1.414Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgInstance;
