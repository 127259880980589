import { ApolloConsumer, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ReactNode, useEffect, useState } from 'react';
import { useIdToken } from 'react-firebase-hooks/auth';
import { firebaseAuth } from '../../firebase';
import { ConfigService } from '../config.service';

export const RequireAuth = (props: { children: ReactNode }) => {
  const [idToken, setIdToken] = useState('');
  const [user] = useIdToken(firebaseAuth);

  useEffect(() => {
    if (!user) {
      return;
    }
    user.getIdToken().then(token => {
      if (import.meta.env.DEV) {
        console.log(token);
      }
      setIdToken(token);
    });
  }, [user]);

  if (!idToken) {
    return <></>;
  }

  return (
    <ApolloConsumer>
      {client => {
        const httpLink = createHttpLink({
          uri: ConfigService.apiUrl + '/graphql',
        });

        const authLink = setContext((_, { headers }) => {
          return {
            headers: {
              ...headers,
              authorization: `Bearer ${idToken}`,
            },
          };
        });

        client.setLink(authLink.concat(httpLink));
        return <>{props.children}</>;
      }}
    </ApolloConsumer>
  );
};

export default RequireAuth;
