import { SVGProps } from 'react';
const SvgFigma = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.32 0h15.36a8.642 8.642 0 0 1 3.96 16.32A8.642 8.642 0 0 1 40.32 24c0 4.77-3.87 8.64-8.64 8.64a8.625 8.625 0 0 1-6.72-3.21v9.93c0 4.77-3.87 8.64-8.64 8.64a8.642 8.642 0 0 1-3.96-16.32 8.642 8.642 0 0 1 0-15.36A8.642 8.642 0 0 1 16.32 0ZM9.6 8.64c0-3.71 3.01-6.72 6.72-6.72h6.72v13.44H16.3a6.723 6.723 0 0 1-6.7-6.72ZM23.04 23.91a9.249 9.249 0 0 0 0 .182v6.629h-6.72c-3.71 0-6.72-3.01-6.72-6.72 0-3.702 3-6.709 6.7-6.72H23.04v6.629Zm1.92.171v-.16a6.723 6.723 0 0 1 6.692-6.64h.048c3.7.011 6.7 3.018 6.7 6.72 0 3.71-3.01 6.72-6.72 6.72a6.723 6.723 0 0 1-6.72-6.64Zm1.29-6.8a8.705 8.705 0 0 0-1.29 1.29v-1.29h1.29Zm5.45-1.92H24.96V1.92h6.72c3.71 0 6.72 3.01 6.72 6.72 0 3.702-3 6.709-6.7 6.72ZM16.32 32.64c-3.71 0-6.72 3.01-6.72 6.72s3.01 6.72 6.72 6.72 6.72-3.01 6.72-6.72v-6.72h-6.72Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFigma;
