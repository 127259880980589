export function pluralize(word: string, count: number, plural: string): string {
  return count === 1 ? word : plural;
}

export interface TruncateOptions {
  maxLength: number;
  truncateWith?: string;
  truncatePosition?: 'start' | 'middle' | 'end';
}

export function truncate(subject: string, options: TruncateOptions): string {
  const {
    maxLength,
    truncateWith = '...',
    truncatePosition = 'middle',
  } = options;
  const { length } = subject;

  if (length <= maxLength) {
    return subject;
  }

  if (truncatePosition === 'start') {
    return `${truncateWith}${subject.slice(length - maxLength)}`;
  }

  if (truncatePosition === 'end') {
    return `${subject.slice(0, maxLength)}${truncateWith}`;
  }

  return `${subject.slice(0, maxLength / 2)}${truncateWith}${subject.slice(
    length - maxLength / 2
  )}`;
}
