import * as R from '@radix-ui/react-select';
import { Icons } from '../../../';
import { forwardRef } from 'react';
import * as css from './Select.css';

export {
  type SelectValueProps,
  type SelectTriggerProps,
  type SelectIconProps,
  type SelectViewportProps,
  type SelectContentProps,
  type SelectPortalProps,
  type SelectGroupProps,
  type SelectSeparatorProps,
  type SelectLabelProps,
  type SelectScrollUpButtonProps,
  type SelectScrollDownButtonProps,
} from '@radix-ui/react-select';

export { Root } from '@radix-ui/react-select';

export const Trigger = (props: R.SelectTriggerProps) => (
  <R.SelectTrigger className={css.trigger} {...props} />
);

export const Value = (props: R.SelectValueProps) => (
  <R.SelectValue {...props} />
);

export const Item = forwardRef<HTMLDivElement, R.SelectItemProps>(
  ({ children, ...props }, ref) => {
    return (
      <R.SelectItem className={css.item} {...props} ref={ref}>
        <R.ItemText>{children}</R.ItemText>
        <R.ItemIndicator className={css.itemIndicator}>
          <Icons.Check />
        </R.ItemIndicator>
      </R.SelectItem>
    );
  }
);

Item.displayName = 'SelectItem';

export const SelectIcon = (props: R.SelectIconProps) => (
  <R.SelectIcon {...props} className={css.icon} />
);

export const Viewport = (props: R.SelectViewportProps) => (
  <R.SelectViewport {...props} className={css.viewport} />
);

export const Content = (props: R.SelectContentProps) => (
  <R.SelectContent {...props} className={css.content} />
);

export const Portal = (props: R.SelectPortalProps) => (
  <R.SelectPortal {...props} />
);

export const Group = (props: R.SelectGroupProps) => (
  <R.SelectGroup {...props} />
);

export const Separator = (props: R.SelectSeparatorProps) => (
  <R.SelectSeparator {...props} className={css.separator} />
);

export const Label = (props: R.SelectLabelProps) => (
  <R.SelectLabel {...props} className={css.label} />
);

export const ScrollUpButton = (props: R.SelectScrollUpButtonProps) => (
  <R.SelectScrollUpButton {...props} className={css.scrollButton} />
);

export const ScrollDownButton = (props: R.SelectScrollDownButtonProps) => (
  <R.SelectScrollDownButton {...props} className={css.scrollButton} />
);
