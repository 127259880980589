import { SVGProps } from 'react';
const SvgClubhouse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="currentColor"
      d="M3.483 35.75A3.493 3.493 0 0 0 0 39.233a3.493 3.493 0 0 0 3.483 3.484 3.493 3.493 0 0 0 3.484-3.484 3.482 3.482 0 0 0-3.484-3.483ZM46.367 5.333l-21.934 7V5.417l-23.65 7.55v20.05l20.4-6.517v6.883L48 24.833l-7.267-7.1 5.634-12.4ZM21.183 23.067 4.05 28.533V15.35l17.133-5.467v13.184Zm20.75.283-17.5 5.583V15.767l16.117-5.15-3.7 7.8 5.083 4.933Z"
    />
  </svg>
);
export default SvgClubhouse;
