import { SVGProps } from 'react';
const SvgVk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.374 3.374C0 6.748 0 12.18 0 23.04v1.92c0 10.861 0 16.292 3.374 19.666C6.748 48 12.18 48 23.04 48h1.92c10.861 0 16.292 0 19.666-3.374C48 41.252 48 35.82 48 24.96v-1.92c0-10.861 0-16.292-3.374-19.666C41.252 0 35.82 0 24.96 0h-1.92C12.179 0 6.748 0 3.374 3.374ZM8.1 14.6c.26 12.48 6.5 19.98 17.44 19.98h.62v-7.14c4.02.4 7.06 3.34 8.28 7.14h5.68c-1.56-5.68-5.66-8.82-8.22-10.02 2.56-1.48 6.16-5.08 7.02-9.96h-5.16c-1.12 3.96-4.44 7.56-7.6 7.9v-7.9H21v13.84c-3.2-.8-7.24-4.68-7.42-13.84H8.1Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgVk;
