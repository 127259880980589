import { SVGProps } from 'react';
const SvgBold = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 12h5.5a3.5 3.5 0 1 1 0 7H8M8 5v14M8 5h4.5a3.5 3.5 0 1 1 0 7H8"
    />
  </svg>
);
export default SvgBold;
