import { SVGProps } from 'react';
const SvgHide = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m4 4 16 16M16.5 16.756C15.147 17.485 13.619 18 12 18c-5.523 0-10-6-10-6s2.088-2.799 5.172-4.573M19.5 14.634C21.056 13.265 22 12 22 12s-4.477-6-10-6c-.338 0-.671.022-1 .064"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.323 13.5a2 2 0 0 1-2.762-2.889"
    />
  </svg>
);
export default SvgHide;
