import { SVGProps } from 'react';
const SvgDummyCircleCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 12a4 4 0 1 0 8 0 4 4 0 0 0-8 0Z"
    />
  </svg>
);
export default SvgDummyCircleCircle;
