import { ConfigService } from '../config.service';

export interface Tenant {
  tenantId: string;
  customerId: string;
  name: string;
  branding: Record<string, string>;
}

class AuthServiceSingleton {
  async getTenant(guid: string): Promise<Tenant> {
    try {
      const data = await fetch(
        ConfigService.apiUrl + `/tenant-by-guid?guid=${guid}`,
        {
          method: 'GET',
        }
      ).then(response => response.json());

      if (!data.tenantId) {
        throw new Error('Invalid tenant');
      }

      return data;
    } catch (e) {
      console.log({ e });
      return { tenantId: '', customerId: '', name: '', branding: {} };
    }
  }
}

export const AuthService = new AuthServiceSingleton();
