import { SVGProps } from 'react';
const SvgYouTube = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="currentColor"
      d="M47.522 14.4s-.469-3.31-1.913-4.762c-1.828-1.913-3.872-1.922-4.809-2.035-6.712-.487-16.79-.487-16.79-.487h-.02s-10.077 0-16.79.487c-.938.113-2.981.122-4.81 2.035C.948 11.09.488 14.4.488 14.4S0 18.29 0 22.172v3.637c0 3.882.478 7.772.478 7.772s.469 3.31 1.903 4.763c1.828 1.912 4.228 1.847 5.297 2.053 3.844.366 16.322.478 16.322.478s10.087-.019 16.8-.497c.938-.112 2.981-.122 4.81-2.034 1.443-1.453 1.912-4.763 1.912-4.763S48 29.701 48 25.81v-3.637c0-3.881-.478-7.772-.478-7.772ZM19.04 30.225v-13.49l12.965 6.768-12.965 6.722Z"
    />
  </svg>
);
export default SvgYouTube;
