import { SVGProps } from 'react';
const SvgTumblr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="currentColor"
      d="M29.2 48c-7.2 0-12.6-3.7-12.6-12.6V21.2H10v-7.7C17.2 11.6 20.2 5.4 20.6 0h7.5v12.2h8.7v9h-8.7v12.4c0 3.7 1.9 5 4.9 5h4.2V48h-8Z"
    />
  </svg>
);
export default SvgTumblr;
