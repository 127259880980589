import { SVGProps } from 'react';
const SvgUnfoldMore = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m16 15-4 4-4-4M8 9l4-4 4 4"
    />
  </svg>
);
export default SvgUnfoldMore;
