import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { ConfigService } from './core/config.service';

export const firebaseApp = initializeApp({
  apiKey: ConfigService.firebaseApiKey,
  authDomain: ConfigService.firebaseAuthDomain,
  projectId: 'nesso-app',
  storageBucket: ConfigService.firebaseStorageBucket,
  messagingSenderId: '230595676449',
  appId: ConfigService.firebaseAppId,
  measurementId: 'G-S7L99EZWTE',
});

export const firebaseAuth = getAuth(firebaseApp);

if (import.meta.env.DEV) {
  connectAuthEmulator(
    firebaseAuth,
    import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_HOST,
    {
      disableWarnings: true,
    }
  );
}
