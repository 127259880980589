import * as R from '@radix-ui/react-popover';
import { ReactNode, forwardRef } from 'react';
import * as css from './Popover.css';

export interface PopoverProps extends R.PopoverProps {
  trigger: ReactNode;
}

export const Popover = forwardRef<HTMLButtonElement, PopoverProps>(
  (props, _ref) => (
    <R.Root>
      <R.Trigger asChild>{props.trigger}</R.Trigger>
      <R.Portal>
        <R.PopoverContent sideOffset={5}>
          <div className={css.popover}>{props.children}</div>
        </R.PopoverContent>
      </R.Portal>
    </R.Root>
  )
);
