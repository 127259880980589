import { SVGProps } from 'react';
const SvgDummyCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 12a6 6 0 1 0 12 0 6 6 0 0 0-12 0Z"
    />
  </svg>
);
export default SvgDummyCircle;
