import { SVGProps } from 'react';
const SvgBuilding02 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2 20h20M12 20h7a1 1 0 0 0 1-1v-5.613a1 1 0 0 0-.332-.744l-4.5-4.043a1 1 0 0 0-1.336 0L12 10.246M11 4H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h7V5a1 1 0 0 0-1-1Z"
    />
  </svg>
);
export default SvgBuilding02;
