import * as UserIcons from './User';
import * as WarningIcons from './Warning';
import * as SystemIcons from './System';
import * as NavigationIcons from './Navigation';
import * as MenuIcons from './Menu';
import * as MediaIcons from './Media';
import * as LiveIcons from './Live';
import * as InterfaceIcons from './Interface';
import * as FileIcons from './File';
import * as EditIcons from './Edit';
import * as CommunicationIcons from './Communication';
import * as CalendarIcons from './Calendar';
import * as ArrowIcons from './Arrow';
import * as SocialIcons from './Social';

export const Icons = {
  ...UserIcons,
  ...WarningIcons,
  ...SystemIcons,
  ...NavigationIcons,
  ...MenuIcons,
  ...MediaIcons,
  ...LiveIcons,
  ...InterfaceIcons,
  ...FileIcons,
  ...EditIcons,
  ...CommunicationIcons,
  ...CalendarIcons,
  ...ArrowIcons,
  ...SocialIcons,
};
