import { SVGProps } from 'react';
const SvgUserVoice = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 19c0-2.21 2.686-4 6-4s6 1.79 6 4M16.828 5.172a3.999 3.999 0 0 1 0 5.657M9 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM19 3a7.07 7.07 0 0 1 0 10"
    />
  </svg>
);
export default SvgUserVoice;
