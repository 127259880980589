import { SVGProps } from 'react';
const SvgCommand = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 15v3a3 3 0 1 0 3-3h-3ZM9 15v3a3 3 0 1 1-3-3h3ZM15 9H9v6h6V9ZM15 9V6a3 3 0 1 1 3 3h-3ZM9 9V6a3 3 0 1 0-3 3h3Z"
    />
  </svg>
);
export default SvgCommand;
