import { SVGProps } from 'react';
const SvgChatDots = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20 5H4a1 1 0 0 0-1 1v12.92a1 1 0 0 0 1.625.78l3.101-2.48a1 1 0 0 1 .625-.22H20a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 11h.002v.002H16V11ZM12 11h.002v.002H12V11ZM8 11h.002v.002H8V11Z"
    />
  </svg>
);
export default SvgChatDots;
