import { SVGProps } from 'react';
const SvgBulb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 21h6M12 3a6 6 0 0 0-4.73 9.692C8.15 13.817 9 15.077 9 16.505V17a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-.495c0-1.428.85-2.688 1.73-3.813A6 6 0 0 0 12 3Z"
    />
  </svg>
);
export default SvgBulb;
