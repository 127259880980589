import { SVGProps } from 'react';
const SvgTicketVoucher = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 9V7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v2M3 15v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2M21 15a3 3 0 1 1 0-6M3 9a3 3 0 1 1 0 6M14 6v12"
    />
  </svg>
);
export default SvgTicketVoucher;
