import { SVGProps } from 'react';
const SvgStrikethrough = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 12c.895 0 1.774.192 2.545.557.348.165.668.362.955.586.347.273.645.586.881.93.432.628.644 1.337.616 2.053-.028.716-.296 1.412-.776 2.017-.48.605-1.153 1.096-1.951 1.421a6.075 6.075 0 0 1-2.584.428 5.865 5.865 0 0 1-2.497-.684c-.74-.402-1.332-.957-1.713-1.605M4 12h16M16.524 6.297c-.381-.648-.973-1.203-1.714-1.605a5.866 5.866 0 0 0-2.496-.684 6.074 6.074 0 0 0-2.584.428c-.798.325-1.472.816-1.952 1.42-.48.606-.747 1.302-.776 2.018-.008.21.005.42.037.626"
    />
  </svg>
);
export default SvgStrikethrough;
