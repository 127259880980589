import { SVGProps } from 'react';
const SvgUsersGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7 20c0-1.657 2.239-3 5-3s5 1.343 5 3M18 14.25c1.766.462 3 1.52 3 2.75M6 14.25c-1.766.462-3 1.52-3 2.75M12 14a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM18 10.236a3 3 0 1 0-4-4.472M6 10.236a3 3 0 0 1 4-4.472"
    />
  </svg>
);
export default SvgUsersGroup;
