import * as R from '@radix-ui/react-radio-group';
import { forwardRef } from 'react';
import { theme } from '../../../theme.css';
import { Column, Row } from '../../Layout';
import * as css from './Radio.css';

export const RadioItem = forwardRef<HTMLButtonElement, R.RadioGroupItemProps>(
  (props, ref) => (
    <Row grow alignItems="center" gap={theme.space.large}>
      <R.Item {...props} className={css.item} ref={ref}>
        <R.Indicator className={css.indicator} />
      </R.Item>
      <label htmlFor={props.id} className={css.label}>
        {props.children}
      </label>
    </Row>
  )
);

export const RadioGroup = forwardRef<HTMLInputElement, R.RadioGroupProps>(
  (props, ref) => (
    <R.Root className={css.group} {...props} ref={ref}>
      <Column gap={theme.space.medium}>{props.children}</Column>
    </R.Root>
  )
);
