import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string;
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: unknown;
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: string;
};

export type Ad = {
  __typename?: 'Ad';
  applications: Array<Application>;
  content: AdContent;
  createdAt: Scalars['DateTime'];
  department: Department;
  id: Scalars['ID'];
  isPublished: Scalars['Boolean'];
  location: Scalars['String'];
  primaryContact: User;
  recruitmentTeam: Array<User>;
  remoteType: RemoteType;
  slug: Scalars['String'];
  stages: Array<Stage>;
  summary: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AdContent = {
  __typename?: 'AdContent';
  html: Scalars['String'];
  json: Scalars['JSON'];
  text: Scalars['String'];
};

export type AdContentUpdateInput = {
  html: Scalars['String'];
  id: Scalars['ID'];
  json: Scalars['JSON'];
  text: Scalars['String'];
};

export type AdCreateInput = {
  departmentId: Scalars['UUID'];
  location: Scalars['String'];
  primaryContactId: Scalars['ID'];
  remoteType: RemoteType;
  title: Scalars['String'];
};

export type AdFilterArgs = {
  assignee?: InputMaybe<Scalars['ID']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
};

export type AdPublishError = NotFoundError | PermissionError;

export type AdPublishInput = {
  id: Scalars['UUID'];
  isPublished: Scalars['Boolean'];
};

export type AdPublishedResponse = {
  __typename?: 'AdPublishedResponse';
  errors?: Maybe<Array<AdPublishError>>;
  record?: Maybe<Ad>;
};

export type AdUpdateError = NotFoundError | PermissionError;

export type AdUpdatedNotification = Notification & {
  __typename?: 'AdUpdatedNotification';
  ad: Ad;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  users: Array<User>;
};

export type AdUpdatedResponse = {
  __typename?: 'AdUpdatedResponse';
  errors?: Maybe<Array<AdUpdateError>>;
  record?: Maybe<Ad>;
};

export type AdsOrderByArgs = {
  dir?: InputMaybe<OrderByDirection>;
  field?: InputMaybe<AdsOrderByField>;
};

export enum AdsOrderByField {
  Title = 'TITLE',
  UpdatedAt = 'UPDATED_AT'
}

export type AdsPaginated = {
  __typename?: 'AdsPaginated';
  nodes: Array<Ad>;
  totalCount: Scalars['Int'];
};

export type Application = {
  __typename?: 'Application';
  ad: Ad;
  candidate: Candidate;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  stage: Stage;
  updatedAt: Scalars['DateTime'];
};

export type ApplicationCreateCandidateInput = {
  email: Scalars['EmailAddress'];
  externalProfiles: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type ApplicationCreateError = NotFoundError | ValidationError;

export type ApplicationCreateInput = {
  adId: Scalars['UUID'];
  candidate: ApplicationCreateCandidateInput;
};

export type ApplicationCreateResponse = {
  __typename?: 'ApplicationCreateResponse';
  errors?: Maybe<Array<ApplicationCreateError>>;
  record?: Maybe<Application>;
};

export type ApplicationFilterArgs = {
  ad?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Array<Scalars['UUID']>>;
  stage?: InputMaybe<Scalars['UUID']>;
};

export enum ApplicationOrderByField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

export type ApplicationStageUpdateError = NotFoundError | ValidationError;

export type ApplicationStageUpdateInput = {
  applicationId: Scalars['UUID'];
  stageId: Scalars['UUID'];
};

export type ApplicationStageUpdateResponse = {
  __typename?: 'ApplicationStageUpdateResponse';
  errors?: Maybe<Array<ApplicationStageUpdateError>>;
  record?: Maybe<Application>;
};

export type ApplicationsOrderByArgs = {
  dir?: InputMaybe<OrderByDirection>;
  field?: InputMaybe<ApplicationOrderByField>;
};

export type ApplicationsPaginated = {
  __typename?: 'ApplicationsPaginated';
  nodes: Array<Application>;
  totalCount: Scalars['Int'];
};

export type Candidate = {
  __typename?: 'Candidate';
  email: Scalars['EmailAddress'];
  externalProfiles: Array<CandidateExternalProfile>;
  id: Scalars['ID'];
  name: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
  tags: Array<CandidateTag>;
};

export type CandidateExternalProfile = {
  __typename?: 'CandidateExternalProfile';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type CandidateTag = {
  __typename?: 'CandidateTag';
  id: Scalars['ID'];
  tag: Scalars['String'];
};

export type CandidatesPaginated = {
  __typename?: 'CandidatesPaginated';
  nodes: Array<Candidate>;
  totalCount: Scalars['Int'];
};

/** An action that a user can perform on a resource */
export enum Claim {
  AdCreate = 'AdCreate',
  AdPublish = 'AdPublish',
  AdRead = 'AdRead',
  AdUpdate = 'AdUpdate',
  ApplicationCreate = 'ApplicationCreate',
  ApplicationMove = 'ApplicationMove',
  ApplicationRead = 'ApplicationRead',
  CandidateCreate = 'CandidateCreate',
  CandidateRead = 'CandidateRead',
  CandidateReadContactInformation = 'CandidateReadContactInformation'
}

export type Department = {
  __typename?: 'Department';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Error = {
  message: Scalars['String'];
};

export type Me = {
  __typename?: 'Me';
  claims: Array<Claim>;
};

export type Mutation = {
  __typename?: 'Mutation';
  adContentUpdate: AdUpdatedResponse;
  adCreate: Ad;
  adPublish: AdPublishedResponse;
  applicationCreate: ApplicationCreateResponse;
  applicationStageUpdate: ApplicationStageUpdateResponse;
};


export type MutationAdContentUpdateArgs = {
  input: AdContentUpdateInput;
};


export type MutationAdCreateArgs = {
  input: AdCreateInput;
};


export type MutationAdPublishArgs = {
  input: AdPublishInput;
};


export type MutationApplicationCreateArgs = {
  input: ApplicationCreateInput;
};


export type MutationApplicationStageUpdateArgs = {
  input: ApplicationStageUpdateInput;
};

export type NotFoundError = Error & {
  __typename?: 'NotFoundError';
  message: Scalars['String'];
};

export type Notification = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export enum OrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PaginationArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PermissionError = Error & {
  __typename?: 'PermissionError';
  message: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  adBySlug?: Maybe<Ad>;
  ads: AdsPaginated;
  applications: ApplicationsPaginated;
  candidateById?: Maybe<Candidate>;
  candidates: CandidatesPaginated;
  departments: Array<Department>;
  me: Me;
  notifications: Array<Notification>;
  users: Array<User>;
};


export type QueryAdBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryAdsArgs = {
  filters?: InputMaybe<AdFilterArgs>;
  orderBy?: InputMaybe<AdsOrderByArgs>;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryApplicationsArgs = {
  filters?: InputMaybe<ApplicationFilterArgs>;
  orderBy?: InputMaybe<ApplicationsOrderByArgs>;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryCandidateByIdArgs = {
  id: Scalars['ID'];
};


export type QueryCandidatesArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

export enum RemoteType {
  Hybrid = 'HYBRID',
  OnPremise = 'ON_PREMISE',
  Remote = 'REMOTE'
}

export type Stage = {
  __typename?: 'Stage';
  id: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['EmailAddress'];
  id: Scalars['ID'];
  name: Scalars['String'];
  photoURL: Scalars['String'];
};

export type ValidationError = Error & {
  __typename?: 'ValidationError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type GetUserClaimsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserClaimsQuery = { __typename?: 'Query', me: { __typename?: 'Me', claims: Array<Claim> } };

export type GetAllDepartmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDepartmentsQuery = { __typename?: 'Query', departments: Array<{ __typename?: 'Department', id: string, name: string }> };

export type GetNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotificationsQuery = { __typename?: 'Query', notifications: Array<{ __typename: 'AdUpdatedNotification', id: string, createdAt: string, ad: { __typename?: 'Ad', id: string, title: string, slug: string, summary: string, applications: Array<{ __typename?: 'Application', id: string }> }, users: Array<{ __typename?: 'User', id: string, name: string, photoURL: string }> }> };

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, name: string, photoURL: string }> };

export type GetAdBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetAdBySlugQuery = { __typename?: 'Query', adBySlug?: { __typename?: 'Ad', id: string, title: string, slug: string, summary: string, createdAt: string, updatedAt: string, stages: Array<{ __typename?: 'Stage', id: string, name: string, order: number }> } | null };

export type GetApplicationByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetApplicationByIdQuery = { __typename?: 'Query', applications: { __typename?: 'ApplicationsPaginated', nodes: Array<{ __typename?: 'Application', id: string, createdAt: string, updatedAt: string, stage: { __typename?: 'Stage', name: string, id: string, order: number }, ad: { __typename?: 'Ad', stages: Array<{ __typename?: 'Stage', id: string, name: string, order: number }> }, candidate: { __typename?: 'Candidate', id: string, name: string, photoUrl?: string | null, externalProfiles: Array<{ __typename?: 'CandidateExternalProfile', url: string }> } }> } };

export type UpdateApplicationStageMutationVariables = Exact<{
  applicationId: Scalars['UUID'];
  stageId: Scalars['UUID'];
}>;


export type UpdateApplicationStageMutation = { __typename?: 'Mutation', applicationStageUpdate: { __typename?: 'ApplicationStageUpdateResponse', record?: { __typename?: 'Application', id: string, candidate: { __typename?: 'Candidate', id: string, name: string }, stage: { __typename?: 'Stage', id: string, name: string, order: number } } | null, errors?: Array<{ __typename?: 'NotFoundError', message: string } | { __typename?: 'ValidationError', message: string, field: string }> | null } };

export type GetApplicationsForStageQueryVariables = Exact<{
  ad: Scalars['UUID'];
  stage?: InputMaybe<Scalars['UUID']>;
}>;


export type GetApplicationsForStageQuery = { __typename?: 'Query', applications: { __typename?: 'ApplicationsPaginated', nodes: Array<{ __typename?: 'Application', id: string, createdAt: string, updatedAt: string, stage: { __typename?: 'Stage', id: string, name: string, order: number }, candidate: { __typename?: 'Candidate', id: string, name: string, email: string, photoUrl?: string | null } }> } };

export type EditAdMutationVariables = Exact<{
  id: Scalars['ID'];
  json: Scalars['JSON'];
  html: Scalars['String'];
  text: Scalars['String'];
}>;


export type EditAdMutation = { __typename?: 'Mutation', adContentUpdate: { __typename?: 'AdUpdatedResponse', record?: { __typename?: 'Ad', id: string, title: string, content: { __typename?: 'AdContent', text: string } } | null } };

export type GetAdForEditQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetAdForEditQuery = { __typename?: 'Query', adBySlug?: { __typename?: 'Ad', id: string, title: string, location: string, content: { __typename?: 'AdContent', json: unknown } } | null };

export type GetAllAdsQueryVariables = Exact<{
  take: Scalars['Int'];
  skip: Scalars['Int'];
  assignee?: InputMaybe<Scalars['ID']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<AdsOrderByArgs>;
}>;


export type GetAllAdsQuery = { __typename?: 'Query', ads: { __typename?: 'AdsPaginated', totalCount: number, nodes: Array<{ __typename?: 'Ad', id: string, slug: string, isPublished: boolean, title: string, summary: string, location: string, remoteType: RemoteType, updatedAt: string, primaryContact: { __typename?: 'User', id: string, name: string, photoURL: string }, recruitmentTeam: Array<{ __typename?: 'User', id: string, name: string, photoURL: string }>, applications: Array<{ __typename?: 'Application', id: string }> }> } };

export type CreateAdMutationVariables = Exact<{
  input: AdCreateInput;
}>;


export type CreateAdMutation = { __typename?: 'Mutation', adCreate: { __typename?: 'Ad', id: string, slug: string } };

export type GetCandidateByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCandidateByIdQuery = { __typename?: 'Query', candidateById?: { __typename?: 'Candidate', id: string, name: string, email: string, photoUrl?: string | null, tags: Array<{ __typename?: 'CandidateTag', id: string, tag: string }>, externalProfiles: Array<{ __typename?: 'CandidateExternalProfile', id: string, url: string }> } | null };

export type GetCandidatesQueryVariables = Exact<{
  take: Scalars['Int'];
  skip: Scalars['Int'];
}>;


export type GetCandidatesQuery = { __typename?: 'Query', candidates: { __typename?: 'CandidatesPaginated', totalCount: number, nodes: Array<{ __typename?: 'Candidate', id: string, name: string, email: string, photoUrl?: string | null, tags: Array<{ __typename?: 'CandidateTag', id: string, tag: string }> }> } };


export const GetUserClaimsDocument = gql`
    query GetUserClaims {
  me {
    claims
  }
}
    `;

/**
 * __useGetUserClaimsQuery__
 *
 * To run a query within a React component, call `useGetUserClaimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserClaimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserClaimsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserClaimsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserClaimsQuery, GetUserClaimsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserClaimsQuery, GetUserClaimsQueryVariables>(GetUserClaimsDocument, options);
      }
export function useGetUserClaimsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserClaimsQuery, GetUserClaimsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserClaimsQuery, GetUserClaimsQueryVariables>(GetUserClaimsDocument, options);
        }
export type GetUserClaimsQueryHookResult = ReturnType<typeof useGetUserClaimsQuery>;
export type GetUserClaimsLazyQueryHookResult = ReturnType<typeof useGetUserClaimsLazyQuery>;
export type GetUserClaimsQueryResult = Apollo.QueryResult<GetUserClaimsQuery, GetUserClaimsQueryVariables>;
export const GetAllDepartmentsDocument = gql`
    query GetAllDepartments {
  departments {
    id
    name
  }
}
    `;

/**
 * __useGetAllDepartmentsQuery__
 *
 * To run a query within a React component, call `useGetAllDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDepartmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllDepartmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDepartmentsQuery, GetAllDepartmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDepartmentsQuery, GetAllDepartmentsQueryVariables>(GetAllDepartmentsDocument, options);
      }
export function useGetAllDepartmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDepartmentsQuery, GetAllDepartmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDepartmentsQuery, GetAllDepartmentsQueryVariables>(GetAllDepartmentsDocument, options);
        }
export type GetAllDepartmentsQueryHookResult = ReturnType<typeof useGetAllDepartmentsQuery>;
export type GetAllDepartmentsLazyQueryHookResult = ReturnType<typeof useGetAllDepartmentsLazyQuery>;
export type GetAllDepartmentsQueryResult = Apollo.QueryResult<GetAllDepartmentsQuery, GetAllDepartmentsQueryVariables>;
export const GetNotificationsDocument = gql`
    query GetNotifications {
  notifications {
    id
    createdAt
    __typename
    ... on AdUpdatedNotification {
      ad {
        id
        title
        slug
        summary
        applications {
          id
        }
      }
      users {
        id
        name
        photoURL
      }
    }
  }
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const GetAllUsersDocument = gql`
    query GetAllUsers {
  users {
    id
    name
    photoURL
  }
}
    `;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const GetAdBySlugDocument = gql`
    query GetAdBySlug($slug: String!) {
  adBySlug(slug: $slug) {
    id
    title
    slug
    summary
    createdAt
    updatedAt
    stages {
      id
      name
      order
    }
  }
}
    `;

/**
 * __useGetAdBySlugQuery__
 *
 * To run a query within a React component, call `useGetAdBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetAdBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetAdBySlugQuery, GetAdBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdBySlugQuery, GetAdBySlugQueryVariables>(GetAdBySlugDocument, options);
      }
export function useGetAdBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdBySlugQuery, GetAdBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdBySlugQuery, GetAdBySlugQueryVariables>(GetAdBySlugDocument, options);
        }
export type GetAdBySlugQueryHookResult = ReturnType<typeof useGetAdBySlugQuery>;
export type GetAdBySlugLazyQueryHookResult = ReturnType<typeof useGetAdBySlugLazyQuery>;
export type GetAdBySlugQueryResult = Apollo.QueryResult<GetAdBySlugQuery, GetAdBySlugQueryVariables>;
export const GetApplicationByIdDocument = gql`
    query GetApplicationById($id: UUID!) {
  applications(filters: {id: [$id]}) {
    nodes {
      id
      createdAt
      updatedAt
      stage {
        name
        id
        order
      }
      ad {
        stages {
          id
          name
          order
        }
      }
      candidate {
        id
        name
        photoUrl
        externalProfiles {
          url
        }
      }
    }
  }
}
    `;

/**
 * __useGetApplicationByIdQuery__
 *
 * To run a query within a React component, call `useGetApplicationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApplicationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetApplicationByIdQuery, GetApplicationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApplicationByIdQuery, GetApplicationByIdQueryVariables>(GetApplicationByIdDocument, options);
      }
export function useGetApplicationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationByIdQuery, GetApplicationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApplicationByIdQuery, GetApplicationByIdQueryVariables>(GetApplicationByIdDocument, options);
        }
export type GetApplicationByIdQueryHookResult = ReturnType<typeof useGetApplicationByIdQuery>;
export type GetApplicationByIdLazyQueryHookResult = ReturnType<typeof useGetApplicationByIdLazyQuery>;
export type GetApplicationByIdQueryResult = Apollo.QueryResult<GetApplicationByIdQuery, GetApplicationByIdQueryVariables>;
export const UpdateApplicationStageDocument = gql`
    mutation UpdateApplicationStage($applicationId: UUID!, $stageId: UUID!) {
  applicationStageUpdate(
    input: {applicationId: $applicationId, stageId: $stageId}
  ) {
    record {
      id
      candidate {
        id
        name
      }
      stage {
        id
        name
        order
      }
    }
    errors {
      ... on NotFoundError {
        message
      }
      ... on ValidationError {
        message
        field
      }
    }
  }
}
    `;
export type UpdateApplicationStageMutationFn = Apollo.MutationFunction<UpdateApplicationStageMutation, UpdateApplicationStageMutationVariables>;

/**
 * __useUpdateApplicationStageMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationStageMutation, { data, loading, error }] = useUpdateApplicationStageMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      stageId: // value for 'stageId'
 *   },
 * });
 */
export function useUpdateApplicationStageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicationStageMutation, UpdateApplicationStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApplicationStageMutation, UpdateApplicationStageMutationVariables>(UpdateApplicationStageDocument, options);
      }
export type UpdateApplicationStageMutationHookResult = ReturnType<typeof useUpdateApplicationStageMutation>;
export type UpdateApplicationStageMutationResult = Apollo.MutationResult<UpdateApplicationStageMutation>;
export type UpdateApplicationStageMutationOptions = Apollo.BaseMutationOptions<UpdateApplicationStageMutation, UpdateApplicationStageMutationVariables>;
export const GetApplicationsForStageDocument = gql`
    query GetApplicationsForStage($ad: UUID!, $stage: UUID) {
  applications(
    orderBy: {field: UPDATED_AT, dir: DESC}
    filters: {stage: $stage, ad: $ad}
  ) {
    nodes {
      id
      createdAt
      updatedAt
      stage {
        id
        name
        order
      }
      candidate {
        id
        name
        email
        photoUrl
      }
    }
  }
}
    `;

/**
 * __useGetApplicationsForStageQuery__
 *
 * To run a query within a React component, call `useGetApplicationsForStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationsForStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationsForStageQuery({
 *   variables: {
 *      ad: // value for 'ad'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useGetApplicationsForStageQuery(baseOptions: Apollo.QueryHookOptions<GetApplicationsForStageQuery, GetApplicationsForStageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApplicationsForStageQuery, GetApplicationsForStageQueryVariables>(GetApplicationsForStageDocument, options);
      }
export function useGetApplicationsForStageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationsForStageQuery, GetApplicationsForStageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApplicationsForStageQuery, GetApplicationsForStageQueryVariables>(GetApplicationsForStageDocument, options);
        }
export type GetApplicationsForStageQueryHookResult = ReturnType<typeof useGetApplicationsForStageQuery>;
export type GetApplicationsForStageLazyQueryHookResult = ReturnType<typeof useGetApplicationsForStageLazyQuery>;
export type GetApplicationsForStageQueryResult = Apollo.QueryResult<GetApplicationsForStageQuery, GetApplicationsForStageQueryVariables>;
export const EditAdDocument = gql`
    mutation EditAd($id: ID!, $json: JSON!, $html: String!, $text: String!) {
  adContentUpdate(input: {id: $id, json: $json, html: $html, text: $text}) {
    record {
      id
      title
      content {
        text
      }
    }
  }
}
    `;
export type EditAdMutationFn = Apollo.MutationFunction<EditAdMutation, EditAdMutationVariables>;

/**
 * __useEditAdMutation__
 *
 * To run a mutation, you first call `useEditAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAdMutation, { data, loading, error }] = useEditAdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      json: // value for 'json'
 *      html: // value for 'html'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useEditAdMutation(baseOptions?: Apollo.MutationHookOptions<EditAdMutation, EditAdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditAdMutation, EditAdMutationVariables>(EditAdDocument, options);
      }
export type EditAdMutationHookResult = ReturnType<typeof useEditAdMutation>;
export type EditAdMutationResult = Apollo.MutationResult<EditAdMutation>;
export type EditAdMutationOptions = Apollo.BaseMutationOptions<EditAdMutation, EditAdMutationVariables>;
export const GetAdForEditDocument = gql`
    query GetAdForEdit($slug: String!) {
  adBySlug(slug: $slug) {
    id
    title
    location
    content {
      json
    }
  }
}
    `;

/**
 * __useGetAdForEditQuery__
 *
 * To run a query within a React component, call `useGetAdForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdForEditQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetAdForEditQuery(baseOptions: Apollo.QueryHookOptions<GetAdForEditQuery, GetAdForEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdForEditQuery, GetAdForEditQueryVariables>(GetAdForEditDocument, options);
      }
export function useGetAdForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdForEditQuery, GetAdForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdForEditQuery, GetAdForEditQueryVariables>(GetAdForEditDocument, options);
        }
export type GetAdForEditQueryHookResult = ReturnType<typeof useGetAdForEditQuery>;
export type GetAdForEditLazyQueryHookResult = ReturnType<typeof useGetAdForEditLazyQuery>;
export type GetAdForEditQueryResult = Apollo.QueryResult<GetAdForEditQuery, GetAdForEditQueryVariables>;
export const GetAllAdsDocument = gql`
    query GetAllAds($take: Int!, $skip: Int!, $assignee: ID, $isPublished: Boolean, $orderBy: AdsOrderByArgs) {
  ads(
    pagination: {take: $take, skip: $skip}
    filters: {assignee: $assignee, isPublished: $isPublished}
    orderBy: $orderBy
  ) {
    totalCount
    nodes {
      id
      slug
      isPublished
      title
      summary
      location
      remoteType
      updatedAt
      primaryContact {
        id
        name
        photoURL
      }
      recruitmentTeam {
        id
        name
        photoURL
      }
      applications {
        id
      }
    }
  }
}
    `;

/**
 * __useGetAllAdsQuery__
 *
 * To run a query within a React component, call `useGetAllAdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAdsQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      assignee: // value for 'assignee'
 *      isPublished: // value for 'isPublished'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllAdsQuery(baseOptions: Apollo.QueryHookOptions<GetAllAdsQuery, GetAllAdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAdsQuery, GetAllAdsQueryVariables>(GetAllAdsDocument, options);
      }
export function useGetAllAdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAdsQuery, GetAllAdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAdsQuery, GetAllAdsQueryVariables>(GetAllAdsDocument, options);
        }
export type GetAllAdsQueryHookResult = ReturnType<typeof useGetAllAdsQuery>;
export type GetAllAdsLazyQueryHookResult = ReturnType<typeof useGetAllAdsLazyQuery>;
export type GetAllAdsQueryResult = Apollo.QueryResult<GetAllAdsQuery, GetAllAdsQueryVariables>;
export const CreateAdDocument = gql`
    mutation CreateAd($input: AdCreateInput!) {
  adCreate(input: $input) {
    id
    slug
  }
}
    `;
export type CreateAdMutationFn = Apollo.MutationFunction<CreateAdMutation, CreateAdMutationVariables>;

/**
 * __useCreateAdMutation__
 *
 * To run a mutation, you first call `useCreateAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdMutation, { data, loading, error }] = useCreateAdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdMutation, CreateAdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdMutation, CreateAdMutationVariables>(CreateAdDocument, options);
      }
export type CreateAdMutationHookResult = ReturnType<typeof useCreateAdMutation>;
export type CreateAdMutationResult = Apollo.MutationResult<CreateAdMutation>;
export type CreateAdMutationOptions = Apollo.BaseMutationOptions<CreateAdMutation, CreateAdMutationVariables>;
export const GetCandidateByIdDocument = gql`
    query GetCandidateById($id: ID!) {
  candidateById(id: $id) {
    id
    name
    email
    photoUrl
    tags {
      id
      tag
    }
    externalProfiles {
      id
      url
    }
  }
}
    `;

/**
 * __useGetCandidateByIdQuery__
 *
 * To run a query within a React component, call `useGetCandidateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCandidateByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCandidateByIdQuery, GetCandidateByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCandidateByIdQuery, GetCandidateByIdQueryVariables>(GetCandidateByIdDocument, options);
      }
export function useGetCandidateByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCandidateByIdQuery, GetCandidateByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCandidateByIdQuery, GetCandidateByIdQueryVariables>(GetCandidateByIdDocument, options);
        }
export type GetCandidateByIdQueryHookResult = ReturnType<typeof useGetCandidateByIdQuery>;
export type GetCandidateByIdLazyQueryHookResult = ReturnType<typeof useGetCandidateByIdLazyQuery>;
export type GetCandidateByIdQueryResult = Apollo.QueryResult<GetCandidateByIdQuery, GetCandidateByIdQueryVariables>;
export const GetCandidatesDocument = gql`
    query GetCandidates($take: Int!, $skip: Int!) {
  candidates(pagination: {skip: $skip, take: $take}) {
    totalCount
    nodes {
      id
      name
      email
      photoUrl
      tags {
        id
        tag
      }
    }
  }
}
    `;

/**
 * __useGetCandidatesQuery__
 *
 * To run a query within a React component, call `useGetCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidatesQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetCandidatesQuery(baseOptions: Apollo.QueryHookOptions<GetCandidatesQuery, GetCandidatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCandidatesQuery, GetCandidatesQueryVariables>(GetCandidatesDocument, options);
      }
export function useGetCandidatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCandidatesQuery, GetCandidatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCandidatesQuery, GetCandidatesQueryVariables>(GetCandidatesDocument, options);
        }
export type GetCandidatesQueryHookResult = ReturnType<typeof useGetCandidatesQuery>;
export type GetCandidatesLazyQueryHookResult = ReturnType<typeof useGetCandidatesLazyQuery>;
export type GetCandidatesQueryResult = Apollo.QueryResult<GetCandidatesQuery, GetCandidatesQueryVariables>;