import { SVGProps } from 'react';
const SvgSlider01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14 15h7M3 15h2M7.5 17.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM20 9h1M3 9h7M16.5 11.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
    />
  </svg>
);
export default SvgSlider01;
