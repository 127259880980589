import { SVGProps } from 'react';
const SvgMoreGridBig = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 18a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM11 18a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM5 18a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM17 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM11 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM5 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM17 6a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM11 6a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM5 6a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
    />
  </svg>
);
export default SvgMoreGridBig;
