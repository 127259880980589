import { SVGProps } from 'react';
const SvgArrowLeftLg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 12h18M16 7l5 5-5 5"
    />
  </svg>
);
export default SvgArrowLeftLg;
