import { SVGProps } from 'react';
const SvgMove = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 21V3M15 18l-3 3-3-3M3 12h18M18 9l3 3-3 3M6 15l-3-3 3-3M9 6l3-3 3 3"
    />
  </svg>
);
export default SvgMove;
