import { SVGProps } from 'react';
const SvgFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 4H5a1 1 0 0 0-1 1v1.586a1 1 0 0 0 .293.707l5.414 5.414a1 1 0 0 1 .293.707v5.968a1 1 0 0 0 1.447.894l2-1a1 1 0 0 0 .553-.894v-4.968a1 1 0 0 1 .293-.707l5.414-5.414A1 1 0 0 0 20 6.586V5a1 1 0 0 0-1-1Z"
    />
  </svg>
);
export default SvgFilter;
