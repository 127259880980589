import { SVGProps } from 'react';
const SvgPuzzle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 6c0 .35-.06.687-.17 1H20a1 1 0 0 1 1 1v3.17a3 3 0 1 0 0 5.659V20a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-3.17a3 3 0 1 1 0-5.659V8a1 1 0 0 1 1-1h3.17A3 3 0 1 1 17 6Z"
    />
  </svg>
);
export default SvgPuzzle;
