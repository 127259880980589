import { SVGProps } from 'react';
const SvgCupcake = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 14H5l.877 6.141a1 1 0 0 0 .99.859h10.266a1 1 0 0 0 .99-.859L19 14ZM14 21v-7M10 21v-7M5 14c-1.288-.31-2-1.473-2-2.75 0-.996.578-1.869 1.443-2.351.328-.183.557-.505.557-.857C5 5.257 7.462 3 10.5 3c1.132 0 2.184.313 3.059.85.371.23.855.253 1.287.146.209-.052.428-.08.654-.08 1.38 0 2.5 1.027 2.5 2.292 0 .31.197.612.51.729 1.46.546 2.49 1.86 2.49 3.396 0 1.43-.697 3.063-2 3.667H5Z"
    />
  </svg>
);
export default SvgCupcake;
