import { SVGProps } from 'react';
const SvgPlanet = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M17 5.6c2.01-.33 3.514-.069 4.053.864 1.104 1.913-2.265 5.927-7.527 8.964C8.267 18.466 3.106 19.378 2 17.464c-.63-1.091.196-2.867 2-4.755"
    />
  </svg>
);
export default SvgPlanet;
