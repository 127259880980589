import { forwardRef } from 'react';
import * as R from '@radix-ui/react-slider';
import { root, track, range, thumb } from './Slider.css';

export type SliderProps = R.SliderProps;

export const Slider = forwardRef<HTMLInputElement, SliderProps>(
  (props, forwardedRef) => {
    return (
      <R.Root {...props} ref={forwardedRef} className={root}>
        <R.SliderTrack className={track}>
          <R.SliderRange className={range} />
        </R.SliderTrack>
        <R.SliderThumb className={thumb} />
      </R.Root>
    );
  }
);

Slider.displayName = 'Slider';
