import { SVGProps } from 'react';
const SvgSkype = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="currentColor"
      d="M24.095 38.446c-7.973 0-11.616-4.062-11.616-7.041a2.584 2.584 0 0 1 2.726-2.585c3.414 0 2.52 5.121 8.89 5.121 3.256 0 5.169-1.96 5.169-3.8 0-1.107-.633-2.371-2.782-2.877L19.37 25.47c-5.714-1.454-6.71-4.631-6.71-7.579 0-6.116 5.595-8.329 10.922-8.329 4.907 0 10.74 2.71 10.74 6.377 0 1.58-1.32 2.427-2.854 2.427-2.916 0-2.426-4.094-8.29-4.094-2.915 0-4.448 1.367-4.448 3.28 0 1.912 2.267 2.56 4.259 2.995l5.247 1.185c5.753 1.296 7.286 4.67 7.286 7.902 0 4.971-3.848 8.812-11.435 8.812h.008Zm22.017-10.518c.228-1.305.341-2.627.34-3.952A22.459 22.459 0 0 0 20.105 1.762 13.213 13.213 0 0 0 13.482 0 13.434 13.434 0 0 0 1.89 20.08a22.475 22.475 0 0 0 26.015 26.158A13.205 13.205 0 0 0 34.52 48a13.434 13.434 0 0 0 11.593-20.072Z"
    />
  </svg>
);
export default SvgSkype;
