import { assignInlineVars } from '@vanilla-extract/dynamic';
import { forwardRef } from 'react';
import * as css from './Avatar.css';

export interface AvatarProps extends React.AllHTMLAttributes<HTMLDivElement> {
  size: number;
  imageUrl: string;
}

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ size, imageUrl, ...props }, ref) => (
    <div
      ref={ref}
      className={css.avatar}
      {...props}
      style={assignInlineVars({
        [css.size]: `${size}px`,
      })}
    >
      <img src={imageUrl} className={css.image} loading="lazy" />
    </div>
  )
);
