import { SVGProps } from 'react';
const SvgDiscord = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="currentColor"
      d="M40.634 8.311a39.581 39.581 0 0 0-9.77-3.03.148.148 0 0 0-.157.074c-.422.75-.89 1.73-1.217 2.5a36.542 36.542 0 0 0-10.973 0 25.261 25.261 0 0 0-1.236-2.5.154.154 0 0 0-.157-.074 39.472 39.472 0 0 0-9.77 3.03.14.14 0 0 0-.064.056C1.067 17.663-.638 26.73.198 35.687a.165.165 0 0 0 .063.113c4.105 3.015 8.082 4.845 11.986 6.058a.155.155 0 0 0 .168-.055 28.448 28.448 0 0 0 2.452-3.988.152.152 0 0 0-.083-.212 26.217 26.217 0 0 1-3.745-1.784.154.154 0 0 1-.015-.256c.252-.188.504-.384.744-.583a.149.149 0 0 1 .155-.02c7.856 3.586 16.36 3.586 24.123 0a.148.148 0 0 1 .157.019c.24.198.492.396.745.584.087.065.082.2-.013.256A24.604 24.604 0 0 1 33.19 37.6a.153.153 0 0 0-.081.214 31.937 31.937 0 0 0 2.45 3.986.152.152 0 0 0 .168.057c3.922-1.213 7.9-3.043 12.005-6.058a.154.154 0 0 0 .062-.11c1.001-10.355-1.676-19.349-7.097-27.322a.122.122 0 0 0-.062-.057ZM16.04 30.234c-2.365 0-4.314-2.171-4.314-4.838 0-2.666 1.911-4.838 4.314-4.838 2.422 0 4.352 2.19 4.314 4.838 0 2.667-1.911 4.838-4.314 4.838Zm15.95 0c-2.365 0-4.314-2.171-4.314-4.838 0-2.666 1.91-4.838 4.314-4.838 2.421 0 4.351 2.19 4.313 4.838 0 2.667-1.892 4.838-4.313 4.838Z"
    />
  </svg>
);
export default SvgDiscord;
