import { SVGProps } from 'react';
const SvgSignal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="currentColor"
      d="m18.238.7.539 2.183a21.792 21.792 0 0 0-6.028 2.495l-1.152-1.93A23.703 23.703 0 0 1 18.238.702Zm11.524 0-.539 2.183a21.792 21.792 0 0 1 6.028 2.495l1.166-1.93A23.873 23.873 0 0 0 29.762.702ZM3.449 11.59A23.83 23.83 0 0 0 .7 18.238l2.182.539a21.794 21.794 0 0 1 2.495-6.028l-1.93-1.159ZM2.249 24c0-1.091.081-2.18.245-3.26L.27 20.4a24.002 24.002 0 0 0 0 7.194l2.223-.335A21.784 21.784 0 0 1 2.248 24Zm34.154 20.545-1.152-1.923a21.667 21.667 0 0 1-6.021 2.495l.539 2.182a23.887 23.887 0 0 0 6.634-2.754ZM45.752 24c0 1.091-.083 2.18-.246 3.26l2.223.333c.361-2.384.361-4.809 0-7.193l-2.223.34a21.71 21.71 0 0 1 .245 3.26Zm1.547 5.755-2.182-.539a21.752 21.752 0 0 1-2.495 6.035l1.93 1.159a23.792 23.792 0 0 0 2.747-6.655ZM27.26 45.506a21.76 21.76 0 0 1-6.518 0l-.334 2.223c2.382.361 4.804.361 7.186 0l-.334-2.223Zm14.251-8.605a21.688 21.688 0 0 1-4.61 4.602l1.337 1.814a24.212 24.212 0 0 0 5.087-5.066l-1.814-1.35ZM36.9 6.49a21.724 21.724 0 0 1 4.61 4.61l1.814-1.351a24.178 24.178 0 0 0-5.073-5.073l-1.35 1.814ZM6.49 11.1a21.724 21.724 0 0 1 4.61-4.61L9.748 4.676a24.18 24.18 0 0 0-5.073 5.073l1.814 1.35Zm38.061.49-1.93 1.16c1.131 1.869 1.973 3.899 2.496 6.02l2.182-.539c-.574-2.34-1.5-4.58-2.748-6.64Zm-23.81-9.096a21.758 21.758 0 0 1 6.518 0l.334-2.223a23.953 23.953 0 0 0-7.186 0l.334 2.223ZM7.642 43.917l-4.643 1.078 1.084-4.644-2.19-.511L.81 44.483a2.25 2.25 0 0 0 1.677 2.707c.337.075.686.075 1.023 0l4.643-1.07-.511-2.203Zm-5.284-6.082 2.195.505.75-3.219a21.562 21.562 0 0 1-2.42-5.905L.7 29.755a24.089 24.089 0 0 0 2.209 5.707l-.552 2.373Zm10.5 4.869-3.218.75.511 2.195 2.366-.552c1.8.979 3.718 1.721 5.708 2.21l.538-2.183a21.594 21.594 0 0 1-5.884-2.434l-.02.014ZM24 4.499C13.226 4.505 4.505 13.24 4.505 24.007A19.549 19.549 0 0 0 7.5 34.37l-1.875 8.005 7.998-1.875c9.116 5.735 21.158 3 26.893-6.11 5.734-9.11 3.007-21.15-6.103-26.892a19.55 19.55 0 0 0-10.412-3Z"
    />
  </svg>
);
export default SvgSignal;
