import { SVGProps } from 'react';
const SvgData = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 17V7m12 0v10M18 17c0 1.657-2.686 3-6 3s-6-1.343-6-3M18 12c0 1.657-2.686 3-6 3s-6-1.343-6-3M12 10c3.314 0 6-1.343 6-3s-2.686-3-6-3-6 1.343-6 3 2.686 3 6 3Z"
    />
  </svg>
);
export default SvgData;
