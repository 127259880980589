import { SVGProps } from 'react';
const SvgHeadingH1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m16 10 3-1v10M3 5v7m0 0v7m0-7h8m0-7v7m0 0v7"
    />
  </svg>
);
export default SvgHeadingH1;
