import { SVGProps } from 'react';
const SvgReddit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M48 24c0 13.255-10.745 24-24 24S0 37.255 0 24 10.745 0 24 0s24 10.745 24 24Zm-11.509-3.509A3.51 3.51 0 0 1 40 24c0 1.431-.87 2.667-2.021 3.228.056.337.084.674.084 1.039 0 5.39-6.26 9.74-14.007 9.74s-14.007-4.35-14.007-9.74c0-.365.028-.73.084-1.067-1.235-.561-2.077-1.768-2.077-3.2a3.51 3.51 0 0 1 3.509-3.509c.926 0 1.796.393 2.414.983 2.414-1.769 5.754-2.864 9.488-2.976l1.768-8.365c.056-.168.14-.308.28-.393.141-.084.31-.112.478-.084l5.81 1.235a2.428 2.428 0 0 1 2.218-1.403 2.504 2.504 0 0 1 2.498 2.498 2.504 2.504 0 0 1-2.498 2.498 2.501 2.501 0 0 1-2.498-2.386l-5.193-1.095-1.6 7.495c3.649.14 6.961 1.263 9.347 2.976a3.37 3.37 0 0 1 2.414-.983ZM18.498 24A2.504 2.504 0 0 0 16 26.498a2.504 2.504 0 0 0 2.498 2.498 2.504 2.504 0 0 0 2.498-2.498A2.504 2.504 0 0 0 18.498 24Zm5.53 10.92c.955 0 4.21-.113 5.923-1.825.252-.253.252-.646.056-.927a.66.66 0 0 0-.926 0c-1.095 1.067-3.369 1.46-5.025 1.46-1.656 0-3.958-.393-5.024-1.46a.66.66 0 0 0-.927 0 .66.66 0 0 0 0 .927c1.684 1.684 4.969 1.824 5.923 1.824Zm2.976-8.422a2.504 2.504 0 0 0 2.498 2.498A2.504 2.504 0 0 0 32 26.498 2.504 2.504 0 0 0 29.502 24a2.504 2.504 0 0 0-2.498 2.498Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgReddit;
