import { SVGProps } from 'react';
const SvgSwatchesPalette = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9.882 18.405 13.498 4.91a.53.53 0 0 0-.375-.648L7.384 2.724a.53.53 0 0 0-.648.375L3.12 16.593a3.5 3.5 0 1 0 6.762 1.812Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6.5 21h13.97a.53.53 0 0 0 .53-.53v-5.94M6.5 17.6h.002v.002H6.5V17.6Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m7.98 20.671 12.662-5.904a.53.53 0 0 0 .256-.704L18.387 8.68a.53.53 0 0 0-.704-.256l-5.653 2.636"
    />
  </svg>
);
export default SvgSwatchesPalette;
