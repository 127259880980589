import { SVGProps } from 'react';
const SvgSave = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20 21H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.055a1 1 0 0 1 .743.331l3.945 4.384a1 1 0 0 1 .257.669V20a1 1 0 0 1-1 1Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7 15a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v6H7v-6ZM15 7H9"
    />
  </svg>
);
export default SvgSave;
