import * as css from './NotificationList.css';
import {
  GetNotificationsQuery,
  useGetNotificationsQuery,
} from '../../../generated/graphql';
import { ReactElement } from 'react';
import { AdUpdatedNotification } from './NotificationItems';
import React from 'react';

export type NotificationType = GetNotificationsQuery['notifications'][0];

export const NotificationList = () => {
  const { data, loading } = useGetNotificationsQuery();

  if (loading || !data) {
    return <>...</>;
  }

  const map: Record<
    NotificationType['__typename'],
    (props: { notification: NotificationType }) => ReactElement
  > = {
    AdUpdatedNotification,
  };

  return (
    <div className={css.list}>
      {data.notifications.map(notification =>
        React.cloneElement(map[notification.__typename]({ notification }), {
          key: notification.id,
        })
      )}
    </div>
  );
};
