import { SVGProps } from 'react';
const SvgCloudUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 19a4 4 0 1 0-.07-8A7.002 7.002 0 0 0 5.668 9.01 5 5 0 0 0 6 19h13Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 16v-6M9 12l3-2 3 2"
    />
  </svg>
);
export default SvgCloudUpload;
