import { ReactNode, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useGetUserClaimsLazyQuery } from '../../generated/graphql';
import { userClaims } from '../user/user.state';

export interface ProvideClaimProps {
  children: ReactNode | ReactNode[];
}

export function ProvideClaims({ children }: ProvideClaimProps) {
  const [getUserClaims] = useGetUserClaimsLazyQuery();
  const [claims, setUserClaims] = useRecoilState(userClaims);

  useEffect(() => {
    getUserClaims().then(({ data }) => {
      setUserClaims(data?.me.claims ?? []);
    });
  }, [claims]);

  return <>{children}</>;
}
