import { darkThemeClass, themeClass } from '@nesso/ui';
import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import RequireAuth from './core/auth/RequireAuth';
import { Menu } from './core/nav/Menu';
import LoginPage from './core/auth/LoginPage/LoginPage';
import { useAuthState } from 'react-firebase-hooks/auth';
import './firebase';
import { firebaseAuth } from './firebase';
import { userState } from './core/user/user.state';
import { SplashScreen } from './core/auth/LoginPage/SplashScreen';
import { themeState } from './routes/settings/state/theme.state';
import { ProvideClaims } from './core/auth/ProvideClaims';

const DashboardRoutes = lazy(
  () => import('./routes/dashboard/DashboardRoutes')
);
const SettingRoutes = lazy(() => import('./routes/settings/SettingRoutes'));
const AdRoutes = lazy(() => import('./routes/ads/AdRoutes'));
const CandidateRoutes = lazy(
  () => import('./routes/candidates/CandidateRoutes')
);

export const LoggedInRoutes = () => (
  <Suspense fallback={'Loading...'}>
    <RequireAuth>
      <ProvideClaims>
        <Menu />
        <Suspense fallback={'Loading...'}>
          <Routes>
            <Route path="/dashboard/*" element={<DashboardRoutes />} />
            <Route path="/settings/*" element={<SettingRoutes />} />
            <Route path="/ads/*" element={<AdRoutes />} />
            <Route path="/candidates/*" element={<CandidateRoutes />} />
          </Routes>
        </Suspense>
      </ProvideClaims>
    </RequireAuth>
  </Suspense>
);

function App() {
  const currentTheme = useRecoilValue(themeState);
  const [user, isLoading] = useAuthState(firebaseAuth);
  const [, setUserState] = useRecoilState(userState);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add(
      currentTheme === 'light' ? themeClass : darkThemeClass
    );
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user && !isLoading) {
      setUserState(state => ({
        ...state,
        id: user.uid,
        photoUrl: user.photoURL ?? '/default_avatar.svg',
        displayName: user.displayName ?? 'anonymous user',
        email: user.email,
      }));
      if (location.pathname.startsWith('/login')) {
        navigate('/dashboard');
      } else {
        navigate(location.pathname);
      }
    }
  }, [user, isLoading]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <Routes>
      <Route path="/*" element={user ? <LoggedInRoutes /> : <LoginPage />} />
    </Routes>
  );
}

export default App;
