import { SVGProps } from 'react';
const SvgLinkHorizontalOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 12h4M15 8h2a4 4 0 0 1 2.646 7M9 16H7a4 4 0 0 1 0-8h1M4 4l16 16"
    />
  </svg>
);
export default SvgLinkHorizontalOff;
