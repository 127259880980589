import { SVGProps } from 'react';
const SvgLockOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 21h14a1 1 0 0 0 1-1V10a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1ZM9 9V6.12C9 4.397 10.3 3 11.904 3c.824 0 1.568.369 2.096.961"
    />
  </svg>
);
export default SvgLockOpen;
