import { SVGProps } from 'react';
const SvgSpotify = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="currentColor"
      d="M24 0C10.745 0 0 10.744 0 24s10.745 24 24 24 24-10.744 24-24S37.255 0 24 0Zm11.71 34.597a2.094 2.094 0 0 1-2.845.801 24.854 24.854 0 0 0-9.712-3.058A24.987 24.987 0 0 0 13 33.392a2.086 2.086 0 1 1-1.27-3.975 29.055 29.055 0 0 1 11.845-1.229c4.021.41 7.832 1.61 11.33 3.57a2.084 2.084 0 0 1 .804 2.84Zm2.872-7.29a2.084 2.084 0 0 1-2.815.887 32.62 32.62 0 0 0-11.833-3.53 32.522 32.522 0 0 0-12.381 1.103 2.086 2.086 0 0 1-1.157-4.011 36.77 36.77 0 0 1 13.96-1.245 36.805 36.805 0 0 1 13.342 3.983 2.087 2.087 0 0 1 .884 2.814Zm1.04-6.064c-.313 0-.63-.07-.931-.219a40.327 40.327 0 0 0-13.978-4.035 40.239 40.239 0 0 0-14.531 1.15 2.087 2.087 0 0 1-1.073-4.035 44.48 44.48 0 0 1 16.03-1.267 44.437 44.437 0 0 1 15.414 4.45 2.088 2.088 0 0 1-.931 3.956Z"
    />
  </svg>
);
export default SvgSpotify;
