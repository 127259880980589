import { SVGProps } from 'react';
const SvgFolders = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 21h12a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1H3v9a1 1 0 0 0 1 1Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 15h3a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H7v4"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10 11 8.745 9.386A1 1 0 0 0 7.955 9H4a1 1 0 0 0-1 1v1M14 5l-1.255-1.614a1 1 0 0 0-.79-.386H8a1 1 0 0 0-1 1v1"
    />
  </svg>
);
export default SvgFolders;
