import { SVGProps } from 'react';
const SvgFileEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 11V4a1 1 0 0 1 1-1h7.563a1 1 0 0 1 .734.32l4.437 4.792a1 1 0 0 1 .266.68V20a1 1 0 0 1-1 1h-6"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 21h2.5l7.5-7.5-2.5-2.5L4 18.5V21ZM9 14l2 2M20 9h-5a1 1 0 0 1-1-1V3"
    />
  </svg>
);
export default SvgFileEdit;
