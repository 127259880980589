class ConfigServiceSingleton {
  get apiUrl(): string {
    const url = import.meta.env.VITE_API_URL;
    if (!url) {
      throw new Error('Missing VITE_API_URL environment variable');
    }
    return url! as string;
  }

  get firebaseApiKey(): string {
    const key = import.meta.env.VITE_FIREBASE_API_KEY;
    if (!key) {
      throw new Error('Missing FIREBASE_API_KEY environment variable');
    }
    return key! as string;
  }

  get firebaseAppId(): string {
    const appId = import.meta.env.VITE_FIREBASE_APP_ID;
    if (!appId) {
      throw new Error('Missing FIREBASE_APP_ID environment variable');
    }
    return appId! as string;
  }

  get firebaseAuthDomain(): string {
    const domain = import.meta.env.VITE_FIREBASE_AUTH_DOMAIN;
    if (!domain) {
      throw new Error('Missing FIREBASE_AUTH_DOMAIN environment variable');
    }
    return domain! as string;
  }

  get firebaseAuthEmulatorUrl(): string {
    const authHost = import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_HOST;
    if (!authHost) {
      throw new Error(
        'Missing VITE_FIREBASE_AUTH_EMULATOR_HOST environment variable'
      );
    }
    return authHost! as string;
  }

  get firebaseStorageBucket(): string {
    const bucket = import.meta.env.VITE_FIREBASE_STORAGE_BUCKET;
    if (!bucket) {
      throw new Error('Missing FIREBASE_STORAGE_BUCKET environment variable');
    }
    return bucket! as string;
  }

  get firebaseCloudFunctionApi(): string {
    const url = import.meta.env.VITE_FIREBASE_CLOUD_FUNCTION_API;
    if (!url) {
      throw new Error(
        'Missing FIREBASE_CLOUD_FUNCTION_API environment variable'
      );
    }
    return url! as string;
  }
}

export const ConfigService = new ConfigServiceSingleton();
