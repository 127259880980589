import { signInWithEmailAndPassword } from 'firebase/auth';

import { Button, Column, Input, theme } from '@nesso/ui';
import { useForm } from 'react-hook-form';
import { firebaseAuth } from '../../../../firebase';

export interface SignInWithEmailProps {
  tenantId: string;
}

export interface EmailFormData {
  email: string;
  password: string;
}

export const SignInWithEmail = ({ tenantId }: SignInWithEmailProps) => {
  const { handleSubmit, register } = useForm<EmailFormData>();

  const signIn = ({ email, password }: EmailFormData) => {
    firebaseAuth.tenantId = tenantId;

    signInWithEmailAndPassword(firebaseAuth, email, password);
  };

  return (
    <form onSubmit={handleSubmit(signIn)}>
      <Column gap={theme.space.large} alignItems="center">
        <Input
          required
          type="text"
          autoComplete="email"
          placeholder="E-mail"
          {...register('email')}
        />
        <Input
          required
          type="password"
          placeholder="Password"
          autoComplete="password"
          {...register('password')}
        />
        <Button>Sign in</Button>
      </Column>
    </form>
  );
};
