import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { Column, Icons, theme } from '@nesso/ui';
import { firebaseAuth } from '../../../../firebase';
import * as css from '../Login.css';
import { Link } from 'react-router-dom';

export interface SignInWithProvidersProps {
  tenantId: string;
}

export const SignInWithProviders = ({ tenantId }: SignInWithProvidersProps) => {
  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();

      firebaseAuth.tenantId = tenantId;
      signInWithPopup(firebaseAuth, provider);
    } catch (e) {
      console.log({ e });
    }
  };

  return (
    <Column
      gap={theme.space.large}
      className={css.buttonGroup}
      alignItems="center"
    >
      <button className={css.googleButton} onClick={signInWithGoogle}>
        <Icons.Google width={20} /> Sign in with Google
      </button>

      <button className={css.linkedInButton}>
        <Icons.LinkedIn width={20} /> Sign in with LinkedIn
      </button>

      <p>
        Or <Link to="/login/email">Sign in with E-mail</Link>
      </p>
    </Column>
  );
};
