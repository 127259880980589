import { SVGProps } from 'react';
const SvgUnfoldLess = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m8 19 4-4 4 4M16 5l-4 4-4-4"
    />
  </svg>
);
export default SvgUnfoldLess;
