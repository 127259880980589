import { darkThemeClass, themeClass } from '@nesso/ui';
import { atom } from 'recoil';

export type ThemeState = 'light' | 'dark';

export const themeState = atom<ThemeState>({
  key: '[Settings] Theme',
  default: (localStorage.getItem('theme') as ThemeState | null) ?? 'light',
  effects: [
    ({ onSet }) => {
      onSet(state => {
        const theme = state === 'light' ? themeClass : darkThemeClass;
        localStorage.setItem('theme', state);
        document.body.className = theme;
      });
    },
  ],
});
