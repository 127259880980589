import { SVGProps } from 'react';
const SvgArrowDownSm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 7v10M16 13l-4 4-4-4"
    />
  </svg>
);
export default SvgArrowDownSm;
