import { SVGProps } from 'react';
const SvgPaperclipAttechmentTilt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4.536 11.464 11.43 4.57a5.25 5.25 0 0 1 7.425 7.425L10.9 19.95A3.5 3.5 0 1 1 5.95 15l7.955-7.955A1.75 1.75 0 0 1 16.38 9.52l-6.895 6.894"
    />
  </svg>
);
export default SvgPaperclipAttechmentTilt;
