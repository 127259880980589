import * as css from './styles.css';
import { Avatar, Column, Icons, Row, Tag, TagVariant, theme } from '@nesso/ui';
import { humanReadableDuration } from '@nesso/lib';
import { Link } from 'react-router-dom';
import { NotificationType } from '../NotificationList';

export const AdUpdatedNotification = ({
  notification,
}: {
  notification: NotificationType & { __typename: 'AdUpdatedNotification' };
}) => {
  const user = notification.users[0];

  const suffix =
    notification.users.length > 1
      ? ` (and ${notification.users.length - 1} more) `
      : ' ';

  return (
    <div className={css.notification}>
      <Row gap={theme.space.large} alignItems="start">
        <Avatar imageUrl={user.photoURL || 'default_avatar.svg'} size={40} />
        <Column gap={theme.space.large}>
          <span
            style={{
              font: theme.typography.hint,
              color: theme.color.neutral[600],
            }}
          >
            {humanReadableDuration({
              from: new Date(notification.createdAt),
              format: 'long',
            })}
          </span>
          <p>
            <Link to={`/users/${user.id}`}>
              <strong>{user.name}</strong>
            </Link>
            {suffix}
            <span>updated the</span>{' '}
            <Link to={`ads/${notification.ad.slug}`}>
              <strong>{notification.ad.title}</strong>
            </Link>{' '}
            ad.
          </p>
          <Row gap={theme.space.medium}>
            <Tag variant={TagVariant.Ghost}>
              <span>{notification.ad.applications.length} Candidates</span>
              <Icons.Layers width={24} />
            </Tag>
          </Row>
        </Column>
      </Row>
    </div>
  );
};
