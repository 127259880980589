import { Nav } from './Nav';
import { NavProfile } from './Profile';
import * as css from './Menu.css';

export const Menu = () => (
  <div className={css.menu}>
    <div className={css.nav}>
      <img src="/logo-alt-no-blur.png" width={32} />
      <Nav />
    </div>
    <NavProfile />
  </div>
);
