import { SVGProps } from 'react';
const SvgPinterest = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M24 0C10.744 0 0 10.744 0 24c0 10.172 6.328 18.853 15.253 22.35-.206-1.903-.403-4.81.085-6.881.44-1.875 2.812-11.925 2.812-11.925s-.722-1.435-.722-3.563c0-3.337 1.931-5.831 4.34-5.831 2.044 0 3.038 1.538 3.038 3.384 0 2.063-1.312 5.138-1.987 7.988-.563 2.39 1.2 4.34 3.553 4.34 4.265 0 7.547-4.5 7.547-10.987 0-5.747-4.125-9.769-10.022-9.769-6.825 0-10.838 5.119-10.838 10.416 0 2.062.797 4.275 1.791 5.475.197.234.225.45.169.684-.178.76-.59 2.39-.666 2.719-.103.44-.347.534-.806.319-3-1.397-4.875-5.775-4.875-9.3 0-7.575 5.503-14.522 15.853-14.522 8.325 0 14.794 5.934 14.794 13.865 0 8.27-5.213 14.925-12.45 14.925-2.428 0-4.716-1.265-5.503-2.756 0 0-1.2 4.585-1.491 5.71-.544 2.08-2.006 4.697-2.981 6.29A24.058 24.058 0 0 0 24 48c13.256 0 24-10.744 24-24S37.256 0 24 0Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPinterest;
