import { useEffect, useState } from 'react';
import { Column, theme } from '@nesso/ui';
import { AuthService, Tenant } from '../auth.service';
import * as css from './Login.css';
import { SignInWithEmail } from './SignInWithEmail/SignInWithEmail';
import { Route, Routes } from 'react-router';
import { SignInWithProviders } from './SignInWithProviders/SignInWithProviders';

export const LoginPage = () => {
  const [tenant, setTenant] = useState<Tenant>({
    tenantId: '',
    customerId: '',
    name: '',
    branding: {},
  });

  useEffect(() => {
    const [guid] = window.location.hostname.split('.');
    AuthService.getTenant(guid).then(tenant => {
      setTenant(tenant);
    });
  }, [tenant.tenantId]);

  return (
    <div className={css.page}>
      <div className={css.formWrapper}>
        <Column gap={theme.space.larger} alignItems="center">
          <h1>Hi again! 👋</h1>
          <h5>
            Sign in to your
            <span className={css.customerName}>{tenant.name}</span> workspace
          </h5>
        </Column>
        <hr className={css.separator} />
        <Routes>
          <Route
            path="login/email"
            element={<SignInWithEmail tenantId={tenant.tenantId} />}
          />
          <Route
            path="*"
            element={<SignInWithProviders tenantId={tenant.tenantId} />}
          />
        </Routes>
      </div>
      <aside className={css.decorationWrapper}>
        <img src="/logo-alt.png" />
      </aside>
    </div>
  );
};

export default LoginPage;
