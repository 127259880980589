import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import App from './App';
import { ConfigService } from './core/config.service';
import reportWebVitals from './reportWebVitals';
import './index.css';

const client = new ApolloClient({
  uri: ConfigService.apiUrl + '/graph',
  cache: new InMemoryCache(),
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <RecoilRoot>
    <StrictMode>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </StrictMode>
  </RecoilRoot>
);

reportWebVitals();
