import { SVGProps } from 'react';
const SvgCoffeToGo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6.686 15h10.628m-10.8-2h10.972M7.089 7h9.822a1 1 0 0 1 .996 1.085l-1.029 12a1 1 0 0 1-.996.915H8.118a1 1 0 0 1-.996-.915l-1.029-12A1 1 0 0 1 7.09 7Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m19.145 5.504-1.143-2A1 1 0 0 0 17.134 3H6.866a1 1 0 0 0-.868.504l-1.143 2A1 1 0 0 0 5.723 7h12.554a1 1 0 0 0 .868-1.496Z"
    />
  </svg>
);
export default SvgCoffeToGo;
