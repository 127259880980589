import { SVGProps } from 'react';
const SvgStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m14.986 8.474-2.532-5.49a.5.5 0 0 0-.908 0l-2.533 5.49a.5.5 0 0 1-.395.287l-6.003.712a.5.5 0 0 0-.281.864l4.439 4.105a.5.5 0 0 1 .15.464l-1.178 5.93a.5.5 0 0 0 .735.534l5.275-2.953a.5.5 0 0 1 .489 0l5.276 2.953a.5.5 0 0 0 .734-.534l-1.178-5.93a.5.5 0 0 1 .15-.464l4.44-4.105a.5.5 0 0 0-.281-.864l-6.004-.712a.5.5 0 0 1-.395-.287Z"
    />
  </svg>
);
export default SvgStar;
