import { SVGProps } from 'react';
const SvgTriangle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m21.526 18.5-8.66-15a1 1 0 0 0-1.732 0l-8.66 15A1 1 0 0 0 3.34 20h17.32a1 1 0 0 0 .866-1.5Z"
    />
  </svg>
);
export default SvgTriangle;
