import { SVGProps } from 'react';
const SvgShoppingCart01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 21a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM8 21a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM3 3h1.192a1 1 0 0 1 .977.79l2.662 12.42a1 1 0 0 0 .977.79H17"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 6h13.67a1 1 0 0 1 .962 1.277l-1.731 6a1 1 0 0 1-.961.723H7.73"
    />
  </svg>
);
export default SvgShoppingCart01;
