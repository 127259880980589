import { SVGProps } from 'react';
const SvgPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.646 3H4.895A1.895 1.895 0 0 0 3 4.895C3 13.789 10.21 21 19.105 21A1.895 1.895 0 0 0 21 19.105v-2.75a2 2 0 0 0-1.257-1.858l-2.636-1.054a2 2 0 0 0-2.023.32l-.68.568a2 2 0 0 1-2.695-.122L9.79 12.29a2 2 0 0 1-.122-2.694l.568-.681a2 2 0 0 0 .32-2.023L9.503 4.257A2 2 0 0 0 7.646 3Z"
    />
  </svg>
);
export default SvgPhone;
