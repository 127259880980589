import { SVGProps } from 'react';
const SvgHeart02 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.372 11.891 12 20.001l-7.372-8.11a4.098 4.098 0 1 1 6.232-5.316L12 8l1.14-1.425a4.098 4.098 0 1 1 6.232 5.316Z"
    />
  </svg>
);
export default SvgHeart02;
