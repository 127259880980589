import { assignInlineVars } from '@vanilla-extract/dynamic';
import { FC, ReactNode } from 'react';
import { theme } from '../../../theme.css';
import * as css from './Tag.css';
import { TagVariant } from './TagVariant';

export interface TagProps {
  children: ReactNode;
  variant?: TagVariant;
  backgroundColor?: string;
  textColor?: string;
}

export const Tag: FC<TagProps> = ({
  variant,
  children,
  textColor,
  backgroundColor,
}) => {
  let [bg, fg] = ['', ''];

  const colors: Record<TagVariant, [string, string]> = {
    [TagVariant.Primary]: [theme.color.primary[400], theme.color.neutral[0]],
    [TagVariant.Subtle]: [theme.color.neutral[100], theme.color.primary[800]],
    [TagVariant.Ghost]: [theme.color.transparent, theme.color.neutral[600]],
    [TagVariant.Outline]: [theme.color.transparent, theme.color.primary[800]],
  };

  if (variant !== undefined) {
    [bg, fg] = colors[variant];
  }

  bg = backgroundColor ?? bg ?? theme.color.primary[400];
  fg = textColor ?? fg ?? theme.color.neutral[0];
  const border =
    variant === TagVariant.Outline
      ? `1px solid ${theme.color.primary[200]}`
      : 'none';

  return (
    <div
      className={css.tag}
      style={assignInlineVars({
        [css.backgroundColor]: bg,
        [css.textColor]: fg,
        [css.border]: border,
      })}
    >
      {children}
    </div>
  );
};
