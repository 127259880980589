import { SVGProps } from 'react';
const SvgRuler = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9.171 20.485 20.485 9.171a1 1 0 0 0 0-1.414l-4.242-4.242a1 1 0 0 0-1.415 0L3.515 14.828a1 1 0 0 0 0 1.415l4.242 4.242a1 1 0 0 0 1.414 0ZM6.343 12l2.122 2.121M9.171 9.171l2.122 2.122M12 6.343l2.121 2.122"
    />
  </svg>
);
export default SvgRuler;
