import { SVGProps } from 'react';
const SvgBellOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 17H9v1a3 3 0 1 0 6 0v-1Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 17H5a1 1 0 0 1-.993-.884L4 16v-.586a1 1 0 0 1 .293-.707l.51-.51A.67.67 0 0 0 5 13.721V10c0-1.842.711-3.517 1.874-4.767M5 3l16 16M19 12.999V10a7 7 0 0 0-7-7l-.24.004a6.973 6.973 0 0 0-2.29.467"
    />
  </svg>
);
export default SvgBellOff;
