import { SVGProps } from 'react';
const SvgArchive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 9H6a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1ZM10 14h4M19.274 6H4.726a1 1 0 0 0-.804.405l-.74 1A1 1 0 0 0 3.985 9h16.03a1 1 0 0 0 .803-1.595l-.74-1A1 1 0 0 0 19.274 6Z"
    />
  </svg>
);
export default SvgArchive;
