import { SVGProps } from 'react';
const SvgMenuAlt05 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 17h8M5 12h14M11 7h8"
    />
  </svg>
);
export default SvgMenuAlt05;
