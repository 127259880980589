import { SVGProps } from 'react';
const SvgCoffee = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 20h14M18 8v6h1a3 3 0 1 0 0-6h-1Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 8H4v5a7 7 0 1 0 14 0V8ZM15 3l-1 2M12 3l-1 2M9 3 8 5"
    />
  </svg>
);
export default SvgCoffee;
