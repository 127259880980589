import { atom, selector } from 'recoil';
import { Claim } from '../../generated/graphql';

export interface UserState {
  id: string;
  displayName: string;
  email: string | null;
  photoUrl: string;
}

export const userState = atom<UserState>({
  key: '[USER]',
  default: {
    id: '',
    displayName: '',
    email: null,
    photoUrl: '',
  },
});

export const userClaims = atom<Claim[]>({
  key: '[USER CLAIMS]',
  default: [],
});

export const userFirstname = selector({
  key: '[USER] displayName',
  get: ({ get }) => get(userState).displayName.split(' ')[0],
});

export const userEmail = selector({
  key: '[USER] email',
  get: ({ get }) => get(userState).email,
});

export const userPhotoUrl = selector({
  key: '[USER] photoUrl',
  get: ({ get }) => get(userState).photoUrl,
});

export const currentUserId = selector({
  key: '[USER] id',
  get: ({ get }) => get(userState).id,
});
