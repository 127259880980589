import { SVGProps } from 'react';
const SvgSettingsFuture = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20.16 6.711 12.5 2.29a1 1 0 0 0-1 0L3.84 6.71a1 1 0 0 0-.5.866v8.846a1 1 0 0 0 .5.866l7.66 4.422a1 1 0 0 0 1 0l7.66-4.422a1 1 0 0 0 .5-.866V7.577a1 1 0 0 0-.5-.866Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
    />
  </svg>
);
export default SvgSettingsFuture;
