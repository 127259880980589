import { SVGProps } from 'react';
const SvgMailOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 20V10l-8.135-6.514a1 1 0 0 0-1.229-.017L3 10v10a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m3 10 9.257 6L21 10"
    />
  </svg>
);
export default SvgMailOpen;
