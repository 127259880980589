import { SVGProps } from 'react';
const SvgSwichtLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 6H8a6 6 0 1 0 0 12h8a6 6 0 0 0 0-12Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 12a3 3 0 1 0 6 0 3 3 0 0 0-6 0Z"
    />
  </svg>
);
export default SvgSwichtLeft;
