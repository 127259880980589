import { SVGProps } from 'react';
const SvgDownloadPackage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20 8H4v11a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V8ZM12 11v6"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m15 15-3 2-3-2M17.134 4H6.866a1 1 0 0 0-.868.504L4 8h16l-1.998-3.496A1 1 0 0 0 17.134 4Z"
    />
  </svg>
);
export default SvgDownloadPackage;
