import { SVGProps } from 'react';
const SvgGoogle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M33.687 13.096a13.042 13.042 0 0 0-9.207-3.598c-6.261 0-11.579 4.224-13.475 9.912a14.375 14.375 0 0 0 0 9.189h.009c1.905 5.68 7.213 9.903 13.475 9.903 3.232 0 6.007-.826 8.157-2.287v-.006a11.109 11.109 0 0 0 4.797-7.293H24.48v-9.242h22.637c.283 1.605.415 3.246.415 4.877 0 7.3-2.609 13.472-7.148 17.652l.005.003C36.412 45.875 30.953 48 24.48 48A24.003 24.003 0 0 1 3.033 34.78a24.02 24.02 0 0 1 0-21.552A23.995 23.995 0 0 1 24.48 0a23.066 23.066 0 0 1 16.059 6.244l-6.852 6.852Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGoogle;
