import { assignInlineVars } from '@vanilla-extract/dynamic';
import React, { forwardRef } from 'react';
import { theme } from '../../../theme.css';
import * as css from './Button.css';
import { ButtonVariant } from './ButtonVariant';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  pill?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, pill, ...props }, ref) => {
    variant = variant ?? ButtonVariant.Primary;

    const colors: Record<ButtonVariant, [string, string, string]> = {
      [ButtonVariant.Primary]: [
        theme.color.primary[400],
        theme.color.neutral[0],
        theme.color.primary[300],
      ],
      [ButtonVariant.Subtle]: [
        theme.color.neutral[100],
        theme.color.neutral[800],
        theme.color.neutral[300],
      ],
      [ButtonVariant.Ghost]: [
        theme.color.transparent,
        theme.color.neutral[900],
        theme.color.transparent,
      ],
      [ButtonVariant.Warning]: [
        theme.color.error[500],
        theme.color.neutral[0],
        theme.color.error[400],
      ],
      [ButtonVariant.Outline]: [
        theme.color.transparent,
        theme.color.neutral[400],
        theme.color.transparent,
      ],
    };

    const [background, textColor, hoverColor] = colors[variant];

    return (
      <button
        {...props}
        ref={ref}
        className={css.button}
        data-outline={variant === ButtonVariant.Outline ? 'outline' : ''}
        style={assignInlineVars({
          [css.backgroundColor]: background,
          [css.textColor]: textColor,
          [css.disabledColor]: theme.color.neutral[400],
          [css.hoverColor]: hoverColor,
          [css.borderRadius]: pill
            ? theme.borderRadii.pill
            : theme.borderRadii.medium,
        })}
      >
        {props.children}
      </button>
    );
  }
);

Button.displayName = 'Button';
