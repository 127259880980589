import { SVGProps } from 'react';
const SvgSingleQuotesR = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 17a3 3 0 0 0 3-3v-2M10 8a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v4h-4a1 1 0 0 1-1-1V8Z"
    />
  </svg>
);
export default SvgSingleQuotesR;
