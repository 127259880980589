import { SVGProps } from 'react';
const SvgArrowLeftSm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7 12h10M13 8l4 4-4 4"
    />
  </svg>
);
export default SvgArrowLeftSm;
