import { SVGProps } from 'react';
const SvgBuilding04 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2 20h20M7 10h4M19 9h-4a1 1 0 0 0-1 1v10h6V10a1 1 0 0 0-1-1ZM7 7h4M13 3H5a1 1 0 0 0-1 1v16h10V4a1 1 0 0 0-1-1Z"
    />
  </svg>
);
export default SvgBuilding04;
