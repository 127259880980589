import { SVGProps } from 'react';
const SvgGift = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 12h-6a1 1 0 0 0-1 1v7h7a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1ZM11 12H5a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7v-7a1 1 0 0 0-1-1ZM20 8h-7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1ZM11 8H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1ZM12 8V5.5A2.5 2.5 0 1 1 14.5 8H12ZM12 8V5.5A2.5 2.5 0 1 0 9.5 8H12Z"
    />
  </svg>
);
export default SvgGift;
