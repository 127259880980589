import { Link, useLocation } from 'react-router-dom';
import * as css from './Nav.css';

export function Nav() {
  const links = [
    { url: '/dashboard', label: 'Dashboard' },
    { url: '/candidates', label: 'Candidates' },
    { url: '/ads', label: 'Ads' },
  ];

  const [, activeRoute] = useLocation().pathname.split('/');

  return (
    <ul className={css.nav}>
      {links.map(link => (
        <Link to={link.url} key={link.url}>
          <li
            className={css.navItem}
            data-active={`/${activeRoute}` === link.url}
          >
            {link.label}
          </li>
        </Link>
      ))}
    </ul>
  );
}
