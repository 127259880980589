import { assignInlineVars } from '@vanilla-extract/dynamic';
import { forwardRef, ReactNode } from 'react';
import { theme } from '../../../theme.css';
import { If } from '../../Layout';
import * as css from './Input.css';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  leadingIcon?: ReactNode;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ leadingIcon, ...props }, ref) => (
    <>
      <If when={leadingIcon !== undefined}>
        <div className={css.leadingIcon}>{leadingIcon}</div>
      </If>
      <input
        ref={ref}
        {...props}
        className={css.input}
        style={assignInlineVars({
          [css.paddingLeft]:
            leadingIcon === undefined ? theme.space.large : '48px',
        })}
      />
    </>
  )
);

Input.displayName = 'Input';
